import Api, {
  Accommodation,
  Area,
  Cloud,
  Fare,
  FareAssignation,
  HealthCenter,
  Incident,
  Patient,
  Unit,
  UnitType,
} from '@ambuliz/sabri-core';
import { liveQueryClient } from 'core/live-query-client';
import { subWeeks } from 'date-fns';

export type RequestStatus = 'NOT_CALLED' | 'LOADING' | 'FAILED' | 'SUCCESS';

type PaginatedQuery = {
  page: number;
  limit: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  where?: Record<string, any>;
};

const createPartialPatient = (patient: Cloud.CreatePartialPatientParams) => Cloud.createPartialPatient(patient);

const postFareIncident = ({ fareId, reason, comment }: Cloud.CreateIncidentParams) =>
  Cloud.createIncident({ fareId, reason, comment });

const assignFare = ({ fareId, porterId }: { fareId: string; porterId: string }) =>
  Cloud.assignFare({ fareId, porterId });

const login = ({ username, password }: { username: string; password: string }) => Api.User.logIn(username, password);

const logout = () => Api.User.logOut();

const getFares = () => new Api.Query(Fare).equalTo('isArchived', false).notEqualTo('status', 'QUEUED').findAll();

const getArchivedFares = ({ page, limit, sortBy, sortDirection, where }: PaginatedQuery) => {
  const query = new Api.Query(Fare)
    .withJSON({
      order: `${sortDirection === 'desc' ? '' : '-'}${sortBy}`,
      where: {
        isArchived: true,
        ...where,
      },
    })
    .skip(page * limit)
    .limit(limit);
  // @ts-ignore `withCount` exists
  return query.withCount().find();
};

const getPatients = () => new Api.Query(Patient).descending('updatedAt').limit(10).find();

const getAreas = () => new Api.Query(Area).select(['name', 'parent', 'isSearchable']).findAll({ batchSize: 200 });

const getUnits = () =>
  new Api.Query(Unit).containedIn('type', ['MEDICAL', 'ACCOMMODATION', 'EMERGENCY'] as UnitType[]).findAll();

const getUnitsByName = ({ name }: { name: string }) => new Api.Query(Unit).equalTo('name', name).find();

const getIncidents = () => new Api.Query(Incident).findAll();

const getCurrentUser = () => Api.User.current();

const loginWithSessionToken = (sessionToken: string) => Api.User.become(sessionToken);

const getHealthCenter = () => new Api.Query(HealthCenter).find();

const getFareAssignations = (ids: string[]) => new Api.Query(FareAssignation).containedIn('fare', ids).findAll();

const getFareFromPatient = ({ patient }: { patient: Patient }) =>
  new Api.Query(Fare).equalTo('patient', patient.toPointer()).find();

const getObjectById = (id: string, className: string) => new Api.Query(className).get(id);

const getObjectByIds = (ids: string[], className: string) =>
  new Api.Query(className).containedIn('objectId', ids).findAll();

const removeFareAssignation = ({ fareId, fareAssignationId, porterId }: Cloud.RemoveFareAssignationParams) =>
  Cloud.removeFareAssignation({ fareId, fareAssignationId, porterId });

const deleteFare = ({ fareId, reason }: Cloud.CancelFareParams) => Cloud.cancelFare({ fareId, reason });

const toggleAutodispatch = () => Cloud.toggleAutodispatch();

const getUniquePatients = (patients: Patient[]) => {
  const patientsByIPP: Record<string, Patient[]> = {};

  for (const patient of patients) {
    patientsByIPP[patient.ipp] = [...(patientsByIPP[patient.ipp] || []), patient];
  }

  const uniquePatients: Patient[] = [];
  const now = new Date();

  for (const ipp in patientsByIPP) {
    const visitsByAdmittedAtDesc = Object.values(patientsByIPP[ipp]).sort((a, b) =>
      a.admittedAt && b.admittedAt ? b.admittedAt.getTime() - a.admittedAt.getTime() : 0
    );

    let latestVisit = visitsByAdmittedAtDesc[0];

    // Find the latest current visit in an ACCOMMODATION, MEDICAL or EMERGENCY unit
    if (!!latestVisit?.unit && ['OTHER'].includes(latestVisit?.unit?.type)) {
      for (const visit of visitsByAdmittedAtDesc) {
        if (
          !!visit.unit &&
          !['OTHER'].includes(visit?.unit?.type) &&
          visit.admittedAt &&
          visit.admittedAt <= now &&
          (!visit.dischargedAt || visit.dischargedAt >= now)
        ) {
          latestVisit = visit;
          break;
        }
      }
    }

    uniquePatients.push(latestVisit);
  }

  return uniquePatients.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName));
};

type GetPatientsByNameParams = {
  name: string;
  limit?: number;
  unitId?: string;
  healthCenterId?: string;
};

const getPatientsByName = async ({ name, limit = 50, unitId, healthCenterId }: GetPatientsByNameParams) => {
  const queries = name
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ')
    .split(' ')
    .map((word) => new Api.Query(Patient).contains('fullTextSearch', word.toLowerCase()));

  const query = Api.Query.and(...queries);

  if (unitId) {
    query.matchesKeyInQuery(
      'objectId',
      'visit.objectId',
      new Api.Query(Accommodation).equalTo('unit', Unit.createWithoutData(unitId)).equalTo('status', 'IN_PROGRESS')
    );
  }

  if (healthCenterId) {
    query.equalTo('healthCenter', HealthCenter.createWithoutData(healthCenterId));
  }

  const patients = await query
    .greaterThan('updatedAt', subWeeks(new Date(), 2))
    .limit(limit)
    .ascending('lastName')
    .include('unit', 'area')
    .find();

  return getUniquePatients(patients);
};

const getPatientsByIpp = ({ ipp }: { ipp: string }) => new Api.Query(Patient).equalTo('ipp', ipp).find();

const getPatientsByPan = ({ pan }: { pan: string }) => new Api.Query(Patient).equalTo('pan', pan).find();

const getAreaByExternalId = ({ externalId }: { externalId: string }) =>
  new Api.Query(Area).equalTo('externalIds', externalId).first();

const updateFare = (params: Cloud.UpdateFareParams) => Cloud.updateFare(params);

const fareTick = (params: Cloud.FareTickParams) => Cloud.fareTick(params);

const confirmFare = (params: Cloud.ConfirmFareParams) => Cloud.confirmFare(params);

const cancelConfirmFare = ({ fareId }: Cloud.CancelConfirmFareParams) => Cloud.cancelConfirmFare({ fareId });

const getLiveQuerySubscriptionAsPromise = (query: Parse.Query) =>
  new Promise<Parse.LiveQuerySubscription>((resolve) =>
    resolve(liveQueryClient.subscribe(query, Parse.User.current()?.getSessionToken()))
  );

const subscribeUnitLiveQuery = () => getLiveQuerySubscriptionAsPromise(new Api.Query(Unit));
const subscribeHealthCenterLiveQuery = () => getLiveQuerySubscriptionAsPromise(new Api.Query(HealthCenter));
const subscribePatientLiveQuery = () => getLiveQuerySubscriptionAsPromise(new Api.Query(Patient));
const subscribeFareAssignationLiveQuery = () => getLiveQuerySubscriptionAsPromise(new Api.Query(FareAssignation));

const logoutPorter = ({ porterId }: Cloud.LogoutPorterParams) => Cloud.logoutPorter({ porterId });

export const requests = {
  createPartialPatient,
  login,
  getPatientsByName,
  getPatientsByIpp,
  getPatientsByPan,
  logout,
  getFares,
  getArchivedFares,
  getFareFromPatient,
  getObjectById,
  getObjectByIds,
  getPatients,
  getAreas,
  getCurrentUser,
  getIncidents,
  getHealthCenter,
  getUnits,
  getUnitsByName,
  assignFare,
  removeFareAssignation,
  subscribeFareAssignationLiveQuery,
  subscribeHealthCenterLiveQuery,
  subscribePatientLiveQuery,
  subscribeUnitLiveQuery,
  updateFare,
  fareTick,
  postFareIncident,
  getFareAssignations,
  deleteFare,
  toggleAutodispatch,
  confirmFare,
  cancelConfirmFare,
  getAreaByExternalId,
  loginWithSessionToken,
  logoutPorter,
};

export const requestNames = {
  updateFare: 'updateFare',
  fareTick: 'fareTick',
  login: 'login',
  logout: 'logout',
  getFares: 'getFares',
  getArchivedFares: 'getArchivedFares',
  getObjectById: 'getObjectById',
  getObjectByIds: 'getObjectByIds',
  getIncidents: 'getIncidents',
  getPatients: 'getPatients',
  getAreas: 'getAreas',
  getCurrentUser: 'getCurrentUser',
  postFareIncident: 'postFareIncident',
  getUnits: 'getUnits',
  getHealthCenter: 'getHealthCenter',
  assignFare: 'assignFare',
  getPatientsByName: 'getPatientsByName',
  removeFareAssignation: 'removeFareAssignation',
  deleteFare: 'deleteFare',
  getFareAssignations: 'getFareAssignations',
  toggleAutodispatch: 'toggleAutodispatch',
  confirmFare: 'confirmFare',
  cancelConfirmFare: 'cancelConfirmFare',
  loginWithSessionToken: 'loginWithSessionToken',
  logoutPorter: 'logoutPorter',
} as const;

export type RequestName = keyof typeof requestNames;
