import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ListPagination } from 'common/components';
import { i18n } from 'common/locale';
import { useState } from 'react';
import { OverviewResource } from '../hooks/useOverviewResources';
import { AccommodationResultRow } from './AccommodationResultRow';
import { LoadingRow } from './LoadingRow';

export type AccommodationResultListProps = {
  resources: OverviewResource[];
  count: number;
  onChange: (page: number, rowsPerPage: number) => void;
  isLoading?: boolean;
};

export const AccommodationResultList = ({ resources, count, onChange, isLoading }: AccommodationResultListProps) => {
  const defaultRowsPerPage = 30;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    onChange(newPage, rowsPerPage);
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    onChange(0, newRowsPerPage);
  };

  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 120 }}>{i18n.bedsOverview.department}</TableCell>
              <TableCell style={{ minWidth: 140 }}>{i18n.bedsOverview.unit}</TableCell>
              <TableCell style={{ minWidth: 120 }}>{i18n.bedsOverview.bed}</TableCell>
              <TableCell>{i18n.bedsOverview.status}</TableCell>
              <TableCell style={{ minWidth: 160 }}>{i18n.bedsOverview.patient}</TableCell>
              <TableCell style={{ minWidth: 75 }}>{i18n.bedsOverview.age}</TableCell>
              <TableCell style={{ minWidth: 140 }}>{i18n.bedsOverview.specificities}</TableCell>
              <TableCell style={{ minWidth: 140 }}>{i18n.bedsOverview.unitResponsible}</TableCell>
              <TableCell style={{ minWidth: 180 }}>{i18n.bedsOverview.startDate}</TableCell>
              <TableCell style={{ minWidth: 180 }}>{i18n.bedsOverview.endDate}</TableCell>
              <TableCell>{i18n.bedsOverview.movement}</TableCell>
              <TableCell style={{ minWidth: 200 }}>{i18n.bedsOverview.destination}</TableCell>
              <TableCell>{i18n.bedsOverview.hour}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array.from({ length: 10 }).map((_, index) => <LoadingRow key={index} />)
              : resources.map((resource) => <AccommodationResultRow resource={resource} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <ListPagination
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        count={count}
      />
    </Paper>
  );
};
