import { AccommodationRequest, Cloud } from '@ambuliz/sabri-core';
import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { NewMutationRequest } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';

type CreateNewPerformerRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  accommodationRequest: AccommodationRequest;
};

export const CreateNewPerformerRequestDialogForm = ({
  onClose,
  onBack,
  accommodationRequest,
}: {
  onClose: () => void;
  onBack?: () => void;
  accommodationRequest: AccommodationRequest;
}) => {
  const handleSubmit = async (_: undefined, { performer }: { performer: string }) => {
    await Cloud.createPerformerRequest({
      performerId: performer,
      requestId: accommodationRequest.id,
    });
    onClose();
  };

  return (
    <>
      <DialogHeader onClose={onClose} onBack={onBack} title="Créer une nouvelle demande" />
      <DialogContent>
        <NewMutationRequest
          onCancel={onBack || onClose}
          onSubmit={handleSubmit}
          thesaurusItem={accommodationRequest.thesaurusItem}
        />
      </DialogContent>
    </>
  );
};

export const CreateNewPerformerRequestDialog = ({
  open,
  onClose,
  accommodationRequest,
}: CreateNewPerformerRequestDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <CreateNewPerformerRequestDialogForm accommodationRequest={accommodationRequest} onClose={onClose} />
    </Dialog>
  );
};
