import { ThesaurusItem } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { useLocations } from 'core/locations';
import UnitSelector, { UnitAutocompleteOption } from 'kurt/components/UnitAutocomplete';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type EditMutationPerformingRequestProps = {
  currentUnitId?: string;
  thesaurusItem?: ThesaurusItem;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (_: undefined, { performer }: { performer: string }) => void;
};

const EditMutationPerformingRequest: React.FC<EditMutationPerformingRequestProps> = ({
  currentUnitId,
  thesaurusItem,
  loading = false,
  onCancel,
  onSubmit,
}) => {
  const { units } = useLocations();
  const { ids: unitIds } = useParams();

  const unitId = useMemo(() => (unitIds ? unitIds.split('-')[0] : undefined), [unitIds]);

  const unitOptions = units
    .filter((unit) => ![unitId, currentUnitId].includes(unit.id))
    .map((unit) => ({ value: unit.id, label: unit.name }));
  const [selectedUnit, setSelectedUnit] = useState<UnitAutocompleteOption | null>(null);
  const [error, setError] = useState(false);

  const handleChange = (unit: UnitAutocompleteOption | null) => {
    setError(false);
    setSelectedUnit(unit);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedUnit) {
      onSubmit(undefined, { performer: selectedUnit.value });
    } else {
      setError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Stack>
          {i18n.visitActions.EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST.form.content?.map((line, index) => (
            <Typography variant="h4" color={color.grey[100]} key={index} fontWeight={600}>
              {line}
            </Typography>
          ))}
        </Stack>

        <Stack spacing={2}>
          <Typography variant="body1" color="secondary">
            {i18n.visitActions.EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST.form.inputLabel}
          </Typography>
          <Stack spacing={1}>
            <UnitSelector
              options={unitOptions}
              selected={selectedUnit}
              thesaurusItem={thesaurusItem}
              onChange={(_, value) => handleChange(value)}
            />
            {error && (
              <Typography variant="body2" color="error">
                {i18n.visitActions.EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST.error?.inputError}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton type="submit" loading={loading} disabled={loading || !selectedUnit}>
          {i18n.visitActions.EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST.form.confirmLabel}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default EditMutationPerformingRequest;
