import { useEffect, useState } from 'react';
import { HealthCenter } from 'core/authentication/types';
import CheckCircle from 'common/components/Icons/CheckCircle';
import Admission from 'common/components/Icons/Admission';
import AffectedBedIcon from 'common/components/Icons/AffectedBedIcon';
import NoBedIcon from 'common/components/Icons/NoBedIcon';
import PatientReadyIcon from 'common/components/Icons/PatientReadyIcon';
import ErrorCircleIcon from 'common/components/Icons/ErrorCircleIcon';
import Hourglass from 'common/components/Icons/Hourglass';
import { endOfDay, format } from 'date-fns';
import { Cloud } from '@ambuliz/sabri-core';
import { AccommodationSearchFilter } from './AccommodationSearchFilters';
import Statistics from 'common/components/Statistics';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { Processing } from 'common/components/Icons';

export const OverviewStatistics = ({
  filters,
  healthCenter,
}: {
  filters: AccommodationSearchFilter;
  healthCenter: HealthCenter;
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    amountOfPatientsToBePlaced: 0,
    amountOfPatientWithBed: 0,
    amountOfAdmittedPatients: 0,
    amountOfPendingRequests: 0,
    amountOfBlockedRequests: 0,
    amountOfAcceptedRequests: 0,
    amountOfValidatedRequests: 0,
  });
  const [refreshDate, setRefreshDate] = useState('-');

  const getStatistics = async (filters: AccommodationSearchFilter, healthCenter: HealthCenter) => {
    setLoading(true);
    const filterDate = filters.date || new Date();
    const endOfFilterDate = endOfDay(filterDate);

    const statisticsData = await Cloud.getOverviewStatistics({
      startDate: filterDate,
      endDate: endOfFilterDate,
      healthCenterId: healthCenter.id,
    });

    setData(statisticsData);
    setRefreshDate(format(new Date(), 'dd/MM - HH:mm'));
    setLoading(false);
  };

  const refresh = () => {
    if (filters.date && healthCenter) {
      getStatistics(filters, healthCenter);
    }
  };

  useEffect(() => {
    if (filters.date && healthCenter) {
      getStatistics(filters, healthCenter);
    }
  }, [filters, healthCenter]);

  return (
    <Statistics.Container loading={loading}>
      <Statistics.Card xs={12} sm={4.5} md={5} lg="auto">
        <Statistics.Item title={{ label: 'À placer', color: 'warning', variant: 'chip', Icon: NoBedIcon }}>
          <Statistics.ItemContent justify="center">
            <Statistics.Text variant="lg">{data.amountOfPatientsToBePlaced}</Statistics.Text>
          </Statistics.ItemContent>
        </Statistics.Item>
        <Statistics.Item title={{ label: 'Lit affecté', color: 'success', variant: 'chip', Icon: AffectedBedIcon }}>
          <Statistics.ItemContent justify="center">
            <Statistics.Text variant="lg">{data.amountOfPatientWithBed}</Statistics.Text>
          </Statistics.ItemContent>
        </Statistics.Item>
        <Statistics.Item title={{ label: 'Patient admis', color: 'secondary', variant: 'chip', Icon: Admission }}>
          <Statistics.ItemContent justify="center">
            <Statistics.Text variant="lg">{data.amountOfAdmittedPatients}</Statistics.Text>
          </Statistics.ItemContent>
        </Statistics.Item>
      </Statistics.Card>
      <Grid item xs={12} sm={4.5} md={5} lg="auto">
        <Statistics.Card>
          <Statistics.Item title={{ label: 'En attente', color: 'primary', variant: 'chip', Icon: Hourglass }}>
            <Statistics.ItemContent justify="center">
              <Statistics.Text variant="lg">{data.amountOfPendingRequests}</Statistics.Text>
            </Statistics.ItemContent>
          </Statistics.Item>
          <Statistics.Item title={{ label: 'Bloquée', color: 'error', variant: 'chip', Icon: ErrorCircleIcon }}>
            <Statistics.ItemContent justify="center">
              <Statistics.Text variant="lg">{data.amountOfBlockedRequests}</Statistics.Text>
            </Statistics.ItemContent>
          </Statistics.Item>
          <Statistics.Item title={{ label: 'Acceptée', color: 'warning', variant: 'chip', Icon: PatientReadyIcon }}>
            <Statistics.ItemContent justify="center">
              <Statistics.Text variant="lg">{data.amountOfAcceptedRequests}</Statistics.Text>
            </Statistics.ItemContent>
          </Statistics.Item>
          <Statistics.Item title={{ label: 'Validée', color: 'success', variant: 'chip', Icon: CheckCircle }}>
            <Statistics.ItemContent justify="center">
              <Statistics.Text variant="lg">{data.amountOfValidatedRequests}</Statistics.Text>
            </Statistics.ItemContent>
          </Statistics.Item>
        </Statistics.Card>

        <Stack direction="row" justifyContent="flex-end" marginTop={1} alignItems="center" spacing={2} paddingY={1}>
          <Processing fontSize="small" color="secondary" />

          <Link
            component="button"
            onClick={() => {
              refresh();
            }}
          >
            <Typography variant="link2">Rafraichir les données</Typography>
          </Link>
          <Box borderRadius="50%" width="4px" height="4px" bgcolor="secondary.main" />
          <Typography variant="body2" color="secondary">
            Dernière mise à jour : {refreshDate}
          </Typography>
        </Stack>
      </Grid>
    </Statistics.Container>
  );
};
