import { AccommodationRequest, Patient } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

type UseAccommodationRequestsProps = {
  locationId?: string;
  visitId?: string;
};

const useAccommodationRequests = ({ visitId }: UseAccommodationRequestsProps) => {
  const query = !!visitId
    ? new Parse.Query(AccommodationRequest).include(
        'performerRequests',
        'performerRequests.performer',
        'performerRequests.fulfilledBy',
        'performerRequests.createdBy',
        'thesaurusItem.redirection'
      )
    : null;

  if (visitId) {
    query?.equalTo('visit', Patient.createWithoutData(visitId));
  }

  const { results, isLoading, isEnabled } = useParseQuery(query);

  return {
    accommodationRequests: results,
    isLoading,
    isEnabled,
  };
};

export default useAccommodationRequests;
