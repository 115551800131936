import Api, { ExternalPatientTransport, HealthCenter } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';

type UseExternalTransportsOptions = {
  page: number;
  rowsPerPage: number;
  healthCenterId: string;
};

export const useExternalPatientTransportsPaginated = (options: UseExternalTransportsOptions) => {
  const page = options?.page || 0;
  const rowsPerPage = options?.rowsPerPage || 10;

  const query = new Api.Query(ExternalPatientTransport)
    .limit(rowsPerPage)
    .equalTo('healthCenter', HealthCenter.createWithoutData(options.healthCenterId))
    .include('patient')
    .descending('startAt')
    .skip(page * rowsPerPage);

  const { results, isLoading } = useParseQuery(query, {
    enableLiveQuery: false,
  });
  const { count } = useParseQueryCount(query);

  return { externalTransports: results as ExternalPatientTransport[], count, isLoading };
};
