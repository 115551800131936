import { PorterStatus } from '@ambuliz/sabri-core';
import { useMemo } from 'react';
import usePorters from 'sabri/hooks/usePorters';

export const useAvailablePorters = (enabled?: boolean) => {
  const { porters, isLoading } = usePorters(enabled);

  const availablePorters = useMemo(() => {
    const availablePorters = [];
    for (const porter of porters) {
      if (!porter.status || !AVAILABLE_STATUSES.includes(porter.status)) {
        continue;
      }

      availablePorters.push(porter);
    }

    return availablePorters;
  }, [porters]);

  return { availablePorters, isLoading };
};

const AVAILABLE_STATUSES: PorterStatus[] = ['OFFLINE', 'AVAILABLE', 'ON_BREAK', 'BUSY'];
