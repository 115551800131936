import Api, { Area, HealthCenter } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useAuthentication } from 'core/authentication';

export const useOrphanAreas = () => {
  const { healthCenter } = useAuthentication();

  const { results, isLoading } = useParseQuery(
    new Api.Query(Area)
      .notEqualTo('externalTypeCode', 'hospital')
      .doesNotExist('parent')
      .equalTo('healthCenter', HealthCenter.createWithoutData(healthCenter.id))
  );

  return {
    orphanAreas: results,
    isLoading,
  };
};
