import Api, { Area, ExternalTypeCode, HealthCenter } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useAuthentication } from 'core/authentication';

type UseAreaSearchResultsProps = {
  filters: {
    name?: string;
    type?: ExternalTypeCode | '';
    externalId?: string;
  };
  page?: number;
  pageSize?: number;
};

export const useAreaSearchResults = ({ filters, page = 0, pageSize = 20 }: UseAreaSearchResultsProps) => {
  const { healthCenter } = useAuthentication();
  const query = new Api.Query(Area);

  if (filters.name) {
    query.matches('name', new RegExp(`${filters.name}`, 'i'));
  }

  if (filters.type) {
    query.equalTo('externalTypeCode', filters.type);
  }

  if (filters.externalId) {
    query.matches('externalIds', new RegExp(filters.externalId, 'i'));
  }

  const { results, isLoading } = useParseQuery(
    query
      .ascending('name')
      .limit(pageSize)
      .equalTo('healthCenter', HealthCenter.createWithoutData(healthCenter.id))
      .skip(page * pageSize),
    {
      enabled: !!filters.name || !!filters.type || !!filters.externalId,
    }
  );

  return {
    areaSearchResults: results,
    isLoading,
  };
};
