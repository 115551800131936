import * as amplitude from '@amplitude/analytics-browser';
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';
import { ENVIRONMENT } from 'configuration';

export function initAmplitude() {
  if (ENVIRONMENT.AMPLITUDE_API_KEY && ENVIRONMENT.AMPLITUDE_API_KEY !== 'none') {
    amplitude.init(ENVIRONMENT.AMPLITUDE_API_KEY, {
      appVersion: process.env.REACT_APP_VERSION,
      serverUrl: ENVIRONMENT.HTTP_PROXY ? `${ENVIRONMENT.HTTP_PROXY}/amplitude` : undefined,
    });
    amplitude.add(autocapturePlugin());
  }
}
