import { AccommodationSpecificity, isIsolatedSpecificity } from '@ambuliz/sabri-core';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Accommodated, Covid, Hourglass } from 'common/components/Icons';
import Statistics from 'common/components/Statistics';
import { BadgeIcon } from 'common/components/Statistics/Item/BadgeIcon';
import { i18n } from 'common/locale';
import { theme } from 'common/theme';
import { trackEvent } from 'core/analytics/trackEvent';
import { AccommodationSpecificityBadges } from 'kurt/components';
import { UnitStatisticsType } from '../UnitStatistics';

type SpecificitiesProps = {
  statistics: UnitStatisticsType;
  specificitiesFilter?: AccommodationSpecificity[];
  onSpecificityClick: (specificity: AccommodationSpecificity) => void;
};
const Specificities = ({ statistics, specificitiesFilter = [], onSpecificityClick }: SpecificitiesProps) => {
  const countIsolatedSpecificities: number = Object.entries(statistics).reduce(
    (acc, [key, value]) => (isIsolatedSpecificity(key as AccommodationSpecificity) ? acc + value : acc),
    0
  );
  if (
    statistics.ACCOMMODATED +
      statistics.COVID +
      countIsolatedSpecificities +
      statistics.WAITING_FOR_DOWNSTREAM_BED +
      statistics.ACCOMMODATED_OTHER_UNIT ===
    0
  ) {
    return null;
  }

  const handleSpecificityClick = (specificity: AccommodationSpecificity) => {
    const isActive = specificitiesFilter.includes(specificity);
    trackEvent({
      event_type: 'unit_management_filter_statistics_changed',
      event_properties: {
        value: specificity,
        label: i18n.unitStatistics[specificity as keyof typeof i18n.unitStatistics],
        action: isActive ? 'removed' : 'added',
      },
    });
    onSpecificityClick(specificity);
  };

  const isolatedSpecificities = getIsolatedSpecificities(statistics);

  return (
    <Statistics.Card xs={8} sm={4.5} md={5} lg="auto">
      {!!statistics.COVID && (
        <Statistics.Item
          title={{
            label: i18n.unitStatistics.COVID,
            color: 'error',
            variant: 'default',
            Icon: () => <BadgeIcon color={theme.palette['error'].main} Icon={Covid} />,
          }}
          isActive={specificitiesFilter.includes('COVID')}
          onClick={() => handleSpecificityClick('COVID')}
        >
          <Statistics.ItemContent justify="center">
            <Statistics.Text variant="lg">{statistics.COVID}</Statistics.Text>
          </Statistics.ItemContent>
        </Statistics.Item>
      )}
      {!!countIsolatedSpecificities && (
        <Statistics.Item
          title={{
            label: i18n.unitStatistics.ISOLATED,
            color: 'danger',
            variant: 'default',
            Icon: () => <AccommodationSpecificityBadges specificities={isolatedSpecificities} disableTooltip />,
          }}
          isActive={isolatedSpecificities.some((specificity) => specificitiesFilter.includes(specificity))}
          tooltipProps={{ title: <IsolatedStatisticsTooltip statistics={statistics} />, placement: 'bottom' }}
          onClick={() => handleSpecificityClick('ISOLATED')}
        >
          <Statistics.ItemContent justify="center">
            <Statistics.Text variant="lg">{countIsolatedSpecificities}</Statistics.Text>
          </Statistics.ItemContent>
        </Statistics.Item>
      )}
      {(!!statistics.ACCOMMODATED || !!statistics.ACCOMMODATED_OTHER_UNIT) && (
        <Statistics.Item
          title={{
            label: i18n.unitStatistics.ACCOMMODATED,
            color: 'warning',
            variant: 'default',
            Icon: () => <BadgeIcon color={theme.palette['warning'].main} Icon={Accommodated} />,
          }}
          isActive={specificitiesFilter.includes('ACCOMMODATED')}
          onClick={() => handleSpecificityClick('ACCOMMODATED')}
        >
          <Statistics.ItemContent justify="center" align="baseline">
            <Statistics.Text variant="lg">{statistics.ACCOMMODATED}</Statistics.Text>
            {!!statistics.ACCOMMODATED_OTHER_UNIT && (
              <Tooltip title={i18n.accommodatedOtherUnitTooltip}>
                <Typography variant="body2" fontWeight={600} color="secondary" lineHeight="18px" fontSize={14}>
                  +{statistics.ACCOMMODATED_OTHER_UNIT}
                </Typography>
              </Tooltip>
            )}
          </Statistics.ItemContent>
        </Statistics.Item>
      )}
      {!!statistics.WAITING_FOR_DOWNSTREAM_BED && (
        <Statistics.Item
          title={{
            label: i18n.unitStatistics.WAITING_FOR_DOWNSTREAM_BED,
            color: 'primary',
            variant: 'default',
            Icon: () => <BadgeIcon color={theme.palette['primary'].main} Icon={Hourglass} />,
          }}
          isActive={specificitiesFilter.includes('WAITING_FOR_DOWNSTREAM_BED')}
          onClick={() => handleSpecificityClick('WAITING_FOR_DOWNSTREAM_BED')}
        >
          <Statistics.ItemContent justify="center">
            <Statistics.Text variant="lg">{statistics.WAITING_FOR_DOWNSTREAM_BED}</Statistics.Text>
          </Statistics.ItemContent>
        </Statistics.Item>
      )}
    </Statistics.Card>
  );
};

const IsolatedStatisticsTooltip = ({ statistics }: { statistics: UnitStatisticsType }) => (
  <Stack spacing={2}>
    {!!statistics.ISOLATED_AIR && (
      <Typography variant="body2">
        {i18n.unitStatistics.ISOLATED_AIR} : {statistics.ISOLATED_AIR}
      </Typography>
    )}
    {!!statistics.ISOLATED_CONTACT && (
      <Typography variant="body2">
        {i18n.unitStatistics.ISOLATED_CONTACT} : {statistics.ISOLATED_CONTACT}
      </Typography>
    )}
    {!!statistics.ISOLATED_DROPLET && (
      <Typography variant="body2">
        {i18n.unitStatistics.ISOLATED_DROPLET} : {statistics.ISOLATED_DROPLET}
      </Typography>
    )}
    {!!statistics.ISOLATED_PROTECTOR && (
      <Typography variant="body2">
        {i18n.unitStatistics.ISOLATED_PROTECTOR} : {statistics.ISOLATED_PROTECTOR}
      </Typography>
    )}
    {statistics.ISOLATED > 0 && (
      <Typography variant="body2">
        {i18n.OTHER} : {statistics.ISOLATED}
      </Typography>
    )}
  </Stack>
);

const getIsolatedSpecificities = (statistics: UnitStatisticsType) => {
  const isolatedSpecificities: AccommodationSpecificity[] = [];
  Object.keys(statistics).forEach((key) => {
    if (key.startsWith('ISOLATED') && statistics[key as keyof typeof statistics]) {
      isolatedSpecificities.push(key as AccommodationSpecificity);
    }
  });
  return isolatedSpecificities;
};

export default Specificities;
