import { ThesaurusItem } from '@ambuliz/sabri-core';
import { Card, IconButton, Stack, TextFieldProps, Typography } from '@mui/material';
import CrossIcon from 'common/components/Icons/Cross';
import { useAuthentication } from 'core/authentication';
import React from 'react';
import { ThesaurusUnitsSuggestions } from './ThesaurusUnitsSuggestions';
import UnitAutocomplete from './UnitAutocomplete';

type Option = { value: string; label: string };

type UnitSelectorProps = {
  options: Option[];
  selected: Option | null;
  thesaurusItem?: ThesaurusItem;
  onChange: (_: unknown, value: Option | null) => void;
  error?: { message: string };
  variant?: TextFieldProps['variant'];
  size?: TextFieldProps['size'];
  disabled?: boolean;
};

const UnitSelector: React.FC<UnitSelectorProps> = ({
  options,
  selected,
  thesaurusItem,
  onChange,
  error,
  variant = 'outlined',
  size = 'medium',
  disabled = false,
}) => {
  const { healthCenter } = useAuthentication();

  const handleSuggestionChange = (unitId: string) => {
    onChange(null, options.find((option) => option.value === unitId) || null);
  };
  const isAThesaurusUnit = selected && thesaurusItem?.redirection?.find((unit) => unit.id === selected.value);

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <UnitAutocomplete
          selected={selected}
          onChange={onChange}
          options={options}
          error={error}
          variant={variant}
          size={size}
          disabled={disabled}
        />
        {selected && !isAThesaurusUnit && (
          <Card elevation={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={`${size === 'small' ? 10 : 14}px 12px`}
            >
              <Typography variant={size === 'small' ? 'body2' : 'body1'} fontWeight={600}>
                {selected.label}
              </Typography>
              <IconButton onClick={() => onChange(null, null)} size={size}>
                {<CrossIcon />}
              </IconButton>
            </Stack>
          </Card>
        )}
      </Stack>
      {healthCenter.thesaurusUnitsSuggestionsEnabled && thesaurusItem && (
        <ThesaurusUnitsSuggestions
          thesaurusItem={thesaurusItem}
          selectedUnit={selected?.value}
          disabledUnits={thesaurusItem.redirection
            ?.filter((unit) => !options.some((option) => option.value === unit.id))
            .map((unit) => unit.id)}
          disabled={disabled}
          onChange={handleSuggestionChange}
        />
      )}
    </Stack>
  );
};

export default UnitSelector;
