import { Contact, Occupancy, getAvailableBeds } from '@ambuliz/sabri-core';
import { Chip, Grid, LinearProgress, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import {} from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import CommentIcon from 'common/components/Icons/Comment';
import { IsolatedIcon } from 'common/components/Icons/Isolated';
import { IsolatedAirIcon } from 'common/components/Icons/IsolatedAir';
import { IsolatedContactIcon } from 'common/components/Icons/IsolatedContact';
import { IsolatedDropletIcon } from 'common/components/Icons/IsolatedDroplet';
import { IsolatedProtectorIcon } from 'common/components/Icons/IsolatedProtector';
import { InOutFlow } from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { hyphenIfUndefined, progressValue } from 'common/utils';
import { format, isBefore } from 'date-fns';
import { SpecificityCounterChip } from 'kurt/components/SpecifityChip';
import { OUTDATED_UPDATED_AT, getThresholdedColor } from 'kurt/const';
import { NormalizedUnit } from 'kurt/hooks/useDepartments';
import PhoneContactsTooltip, { PhoneContact } from '../Contacts/PhoneContactTooltip';
import BedsAvailabilities from './BedsAvailabilities';
import CustomGrid from './CustomGrid';
import OccupancyInfos from './OccupancyInfos';

type UnitRowProps = NormalizedUnit & { displayUpdatedAt?: boolean; columns: number[]; isMedium: boolean };

const UnitRow: React.FC<UnitRowProps> = ({
  name,
  occupancy,
  capacityRate,
  autoComputeOccupanciesActivated,
  contacts,
  columns,
  isMedium,
  displayUpdatedAt = false,
}) => {
  const phoneContacts: PhoneContact[] = contacts && contacts?.length > 0 ? mapUnitContactToPhoneContacts(contacts) : [];
  const isolatedOccupancy = occupancy ? countIsolatedOccupancy(occupancy) : 0;

  return (
    <Grid item container>
      <Grid container spacing={4} alignItems="center">
        <CustomGrid item percentile={columns[0]} paddingBottom={4}>
          <Grid container justifyContent="space-between" spacing={4}>
            <Grid item xs container spacing={2} alignItems="center">
              <Grid item>
                <EllipsisTypography fontWeight={600} maxLines={2}>
                  {name}
                </EllipsisTypography>
              </Grid>
              {!!occupancy?.covid && (
                <Grid item>
                  <SpecificityCounterChip specificity="COVID" value={occupancy.covid} />
                </Grid>
              )}
              {!!isolatedOccupancy && (
                <Grid item>
                  <IsolatedCounterChip occupancy={occupancy as Occupancy} count={isolatedOccupancy} />
                </Grid>
              )}
              {!!occupancy?.accomodated && (
                <Grid item>
                  <SpecificityCounterChip specificity="ACCOMMODATED" value={occupancy.accomodated} />
                </Grid>
              )}
              {!!occupancy?.waitingForDownstreamBed && (
                <Grid item>
                  <SpecificityCounterChip
                    specificity="WAITING_FOR_DOWNSTREAM_BED"
                    value={occupancy.waitingForDownstreamBed}
                  />
                </Grid>
              )}
            </Grid>
            {!!occupancy?.comment && (
              <Grid item>
                <Tooltip
                  title={
                    <>
                      <Typography variant="body2" mb={2} fontWeight={600}>
                        {i18n.lastCommentUpdate.short(occupancy.comment.updatedAt)}
                      </Typography>
                      <Typography variant="body2">{occupancy.comment.message}</Typography>
                    </>
                  }
                >
                  <Grid container alignItems="center" sx={{ cursor: 'pointer' }}>
                    <CommentIcon color="secondary" />
                  </Grid>
                </Tooltip>
              </Grid>
            )}
            {phoneContacts.length > 0 && (
              <Grid item>
                <PhoneContactsTooltip contacts={phoneContacts} />
              </Grid>
            )}
          </Grid>
        </CustomGrid>
        <CustomGrid item percentile={columns[1]} paddingBottom={4}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item sx={{ width: 48 }}>
              <LinearProgress
                value={progressValue(capacityRate)}
                color={getThresholdedColor(capacityRate, 'secondary')}
              />
            </Grid>
            <Grid item sx={{ minWidth: 36 }}>
              <Typography>{hyphenIfUndefined(capacityRate)}%</Typography>
            </Grid>
            <Grid item>
              <OccupancyInfos occupancy={occupancy} />
            </Grid>
          </Grid>
        </CustomGrid>
        <CustomGrid item percentile={columns[2]} paddingBottom={4}>
          <InOutFlow admission={occupancy?.admission} discharge={occupancy?.discharge} />
        </CustomGrid>
        <CustomGrid item percentile={columns[3]} paddingBottom={4}>
          <BedsAvailabilities
            {...occupancy?.dDayAvailabilities}
            total={getAvailableBeds(
              occupancy?.dDayAvailabilities?.male,
              occupancy?.dDayAvailabilities?.female,
              occupancy?.dDayAvailabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        <CustomGrid item percentile={columns[4]} paddingBottom={4}>
          <BedsAvailabilities
            {...occupancy?.h24Availabilities}
            total={getAvailableBeds(
              occupancy?.h24Availabilities?.male,
              occupancy?.h24Availabilities?.female,
              occupancy?.h24Availabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        <CustomGrid item percentile={columns[5]} paddingBottom={4}>
          <BedsAvailabilities
            {...occupancy?.h48Availabilities}
            total={getAvailableBeds(
              occupancy?.h48Availabilities?.male,
              occupancy?.h48Availabilities?.female,
              occupancy?.h48Availabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        {displayUpdatedAt && (
          <CustomGrid
            item
            container
            justifyContent="flex-end"
            percentile={columns[6]}
            paddingBottom={4}
            paddingRight={4}
          >
            {autoComputeOccupanciesActivated ? (
              <Typography variant="body2" color="text.secondary">
                {i18n.occupanciesAutoComputeShort}
              </Typography>
            ) : (
              <UnitUpdatedAtCell updatedAt={occupancy?.updatedAt} />
            )}
          </CustomGrid>
        )}
      </Grid>
    </Grid>
  );
};

const mapUnitContactToPhoneContacts = (contacts: Contact[]): PhoneContact[] => {
  const phoneContacts: PhoneContact[] = [];

  for (const { telecoms, name } of contacts) {
    if (telecoms && telecoms.length > 0) {
      const phoneTelecoms = telecoms.filter((telecom) => telecom.system === 'phone');
      phoneContacts.push({ name, phoneNumbers: phoneTelecoms.map((phone) => phone.value) });
    }
  }

  return phoneContacts;
};

const UnitUpdatedAtCell = ({ updatedAt }: { updatedAt?: Date }) => {
  if (updatedAt) {
    const isOutdated = isBefore(updatedAt, OUTDATED_UPDATED_AT);

    return (
      <Typography variant="body2" color={isOutdated ? palette.warning.main : palette.text.secondary}>
        <Typography fontWeight={600} component="span" variant="body2">
          {isOutdated && '⚠'} {format(updatedAt, 'dd/MM')}
        </Typography>
        {' - ' + format(updatedAt, 'HH:mm')}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" color="textSecondary">
      -
    </Typography>
  );
};

const countIsolatedOccupancy = (occupancy: Occupancy) =>
  occupancy.isolated +
  occupancy.isolatedAir +
  occupancy.isolatedDroplet +
  occupancy.isolatedContact +
  occupancy.isolatedProtector;

const IsolatedCounterChip = ({ occupancy, count }: { occupancy: Occupancy; count: number }) => (
  <Tooltip
    placement="top"
    title={
      <Stack spacing={2}>
        <Typography variant="body2" fontWeight={600}>
          {i18n.unitStatistics.ISOLATED}
        </Typography>

        {!!occupancy.isolatedAir && (
          <Typography variant="body2">
            {i18n.unitStatistics.ISOLATED_AIR} : {occupancy.isolatedAir}
          </Typography>
        )}
        {!!occupancy.isolatedContact && (
          <Typography variant="body2">
            {i18n.unitStatistics.ISOLATED_CONTACT} : {occupancy.isolatedContact}
          </Typography>
        )}
        {!!occupancy.isolatedDroplet && (
          <Typography variant="body2">
            {i18n.unitStatistics.ISOLATED_DROPLET} : {occupancy.isolatedDroplet}
          </Typography>
        )}
        {!!occupancy.isolatedProtector && (
          <Typography variant="body2">
            {i18n.unitStatistics.ISOLATED_PROTECTOR} : {occupancy.isolatedProtector}
          </Typography>
        )}
        {!!occupancy.isolated && (
          <Typography variant="body2">
            {i18n.OTHER} : {occupancy.isolated}
          </Typography>
        )}
      </Stack>
    }
  >
    <Chip
      color="danger"
      icon={
        <Stack direction="row" gap={1}>
          {!!occupancy.isolated && <IsolatedIcon sx={{ height: 12, width: 12 }} />}
          {!!occupancy.isolatedAir && <IsolatedAirIcon sx={{ height: 12, width: 12 }} />}
          {!!occupancy.isolatedContact && <IsolatedContactIcon sx={{ height: 12, width: 12 }} />}
          {!!occupancy.isolatedDroplet && <IsolatedDropletIcon sx={{ height: 12, width: 12 }} />}
          {!!occupancy.isolatedProtector && <IsolatedProtectorIcon sx={{ height: 12, width: 12 }} />}
        </Stack>
      }
      label={count}
    />
  </Tooltip>
);

export default UnitRow;
