import { AccommodationRequest, Cloud } from '@ambuliz/sabri-core';
import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { AddressMutationRequest } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';

type AddressMutationRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  accommodationRequest: AccommodationRequest;
};

export const AddressMutationRequestDialogForm = ({
  accommodationRequest,
  onClose,
  onBack,
}: {
  accommodationRequest: AccommodationRequest;
  onClose: () => void;
  onBack?: () => void;
}) => {
  const handleSubmit = async (_: unknown, { performer }: { performer: string }) => {
    await Cloud.createPerformerRequest({ requestId: accommodationRequest.id, performerId: performer });
    onClose();
  };
  return (
    <>
      <DialogHeader onClose={onClose} onBack={onBack} title={i18n.visitActions.ADDRESS_MUTATION_REQUEST.action.label} />
      <DialogContent>
        <AddressMutationRequest
          thesaurusItem={accommodationRequest?.thesaurusItem}
          onCancel={onBack || onClose}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </>
  );
};

export const AddressMutationRequestDialog = ({
  open,
  onClose,
  accommodationRequest,
}: AddressMutationRequestDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <AddressMutationRequestDialogForm accommodationRequest={accommodationRequest} onClose={onClose} />
    </Dialog>
  );
};
