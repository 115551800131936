import Api, {
  AvailabilityPeriod,
  ExternalTypeCode,
  HealthCenter as ParseHealthCenter,
  Unit as ParseUnit,
  UnitType,
} from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useAuthentication } from 'core/authentication';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export interface Unit {
  id: string;
  name: string;
  department?: string;
  type?: UnitType;
  isEmergency?: boolean;
}

export interface Department {
  name: string;
  units: Unit[];
}

export interface HealthCenter {
  id: string;
  name: string;
}

export interface Area {
  id: string;
  name: string;
  unitId: string;
  unitName: string;
  parentId?: string;
  type?: ExternalTypeCode;
  nextSlotAt?: Date;
  availabilityPeriods?: AvailabilityPeriod[];
  excludedFromOccupancy?: boolean;
}

interface LocationContextProps {
  loading: boolean;
  units: Unit[];
  healthCenter: HealthCenter;
  getUnit: (id: string) => Unit;
  departments: Department[];
}

export const LocationContext = createContext({} as LocationContextProps);

const UnitQuery = new Api.Query(ParseUnit)
  .containedIn('type', ['ACCOMMODATION', 'EMERGENCY'])
  .select('name', 'areas', 'department', 'type', 'isEmergency');

export const LocationContextProvider = ({ children }: { children: ReactNode }) => {
  const [units, setUnits] = useState<Record<string, Unit>>({});
  const [loading, setLoading] = useState(true);

  const { isLoggedIn, healthCenter } = useAuthentication();

  if (healthCenter.id) {
    UnitQuery.equalTo('healthCenter', ParseHealthCenter.createWithoutData(healthCenter.id));
  }

  const { results: parseUnits, isLoading: unitsLoading } = useParseQuery(UnitQuery, {
    enableLiveQuery: false,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (!unitsLoading) {
      const units: Record<string, Unit> = {};

      for (const parseUnit of parseUnits) {
        const unit = {
          id: parseUnit.id,
          name: parseUnit.name,
          department: parseUnit.department,
          type: parseUnit.type,
          isEmergency: parseUnit.isEmergency,
        };
        units[unit.id] = unit;
      }
      setUnits(units);
      setLoading(false);
    }
  }, [parseUnits, unitsLoading]);

  const departments = useMemo(() => {
    const sortedUnits = Object.values(units).sort((a, b) => a.name.localeCompare(b.name));
    const departments: Record<string, Department> = {};

    for (const unit of sortedUnits) {
      const department = unit.department || 'Autres';
      if (!departments[department]) {
        departments[department] = { name: department, units: [] };
      }
      departments[department].units.push(unit);
    }

    return Object.values(departments).sort((a, b) => a.name.localeCompare(b.name));
  }, [units]);

  return (
    <LocationContext.Provider
      value={{
        units: useMemo(() => Object.values(units).sort((a, b) => a.name.localeCompare(b.name)), [units]),
        healthCenter,
        getUnit: useCallback((id) => units[id], [units]),
        departments,
        loading,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocations = () => useContext(LocationContext);
