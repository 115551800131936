import { Accommodation, AccommodationRequest } from '@ambuliz/sabri-core';
import { Box, IconButton } from '@mui/material';
import { MoreVertical } from 'common/components/Icons';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import { useRef, useState } from 'react';
import MovementsActionForm from '.';
import DischargeAccommodationCardMenu from './DischargeAccommodationCardMenu';

const MovementActionMenu = ({ movementId, unitId }: { movementId: string; unitId?: string }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [actionOpen, setActionOpen] = useState(false);
  const [action, setAction] = useState<AccommodationAction>();
  const [accommodation, setAccommodation] = useState<Accommodation>();
  const [nextAccommodation, setNextAccommodation] = useState<Accommodation>();
  const [mutation, setMutation] = useState<AccommodationRequest>();

  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setActionOpen(false);
    setAction(undefined);
  };

  const handleAction = async (action: AccommodationAction) => {
    setAction(action);
    setActionOpen(true);
    toggleMenu();
  };

  const onMenuLoaded = ({
    accommodation,
    mutation,
    nextAccommodation,
  }: {
    accommodation: Accommodation;
    mutation?: AccommodationRequest;
    nextAccommodation?: Accommodation;
  }) => {
    setAccommodation(accommodation);
    setNextAccommodation(nextAccommodation);
    setMutation(mutation);
  };

  return (
    <Box display="flex" alignItems="center" onClick={(event) => event.stopPropagation()}>
      <IconButton sx={{ margin: -1 }} ref={menuButtonRef} onClick={toggleMenu}>
        <MoreVertical />
      </IconButton>
      {action && (
        <MovementsActionForm
          action={action}
          open={actionOpen}
          onCancel={handleClose}
          id={movementId}
          requestId={mutation ? mutation.id : undefined}
          performerRequests={mutation?.performerRequests}
          specificities={accommodation?.specificities}
          dischargeDate={accommodation?.endAt}
          admissionDate={accommodation?.startAt}
          bedId={accommodation?.bed?.id}
          nextAccommodationId={nextAccommodation?.id}
          unitId={unitId}
          accommodation={accommodation}
          accommodationRequest={mutation}
        />
      )}
      {menuOpen && (
        <DischargeAccommodationCardMenu
          open={menuOpen}
          anchorEl={menuButtonRef.current}
          onClose={toggleMenu}
          onLoaded={onMenuLoaded}
          movementId={movementId}
          onActionClick={handleAction}
        />
      )}
    </Box>
  );
};

export default MovementActionMenu;
