import { SvgIcon, SvgIconProps } from '@mui/material';

const InsuranceCard: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M6.49992 8H5.83325C5.7412 8 5.66659 7.92538 5.66659 7.83333V7.16666C5.66622 6.89067 5.44258 6.66703 5.16659 6.66666H4.16659C3.8906 6.66703 3.66695 6.89067 3.66659 7.16666V7.83333C3.66659 7.92538 3.59197 8 3.49992 8H2.83325C2.55726 8.00036 2.33362 8.22401 2.33325 8.5V9.5C2.33362 9.77599 2.55726 9.99963 2.83325 10H3.49992C3.59197 10 3.66659 10.0746 3.66659 10.1667V10.8333C3.66695 11.1093 3.8906 11.333 4.16659 11.3333H5.16659C5.44258 11.333 5.66622 11.1093 5.66659 10.8333V10.1667C5.66659 10.1225 5.68414 10.0801 5.7154 10.0488C5.74666 10.0176 5.78905 10 5.83325 10H6.49992C6.77591 9.99963 6.99955 9.77599 6.99992 9.5V8.5C6.99955 8.22401 6.77591 8.00036 6.49992 8Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 1.66666C15.403 1.66666 16 2.26362 16 3V13C16 13.7364 15.403 14.3333 14.6667 14.3333H1.33333C0.596954 14.3333 0 13.7364 0 13V3C0 2.26362 0.596954 1.66666 1.33333 1.66666H14.6667ZM1.33333 12.6667C1.33333 12.8508 1.48257 13 1.66667 13H14.3333C14.5174 13 14.6667 12.8508 14.6667 12.6667V5.66666C14.6667 5.48257 14.5174 5.33333 14.3333 5.33333H1.66667C1.48257 5.33333 1.33333 5.48257 1.33333 5.66666V12.6667Z"
      />
      <path d="M12.8334 7.83333H9.66675C9.39061 7.83333 9.16675 8.05719 9.16675 8.33333C9.16675 8.60947 9.39061 8.83333 9.66675 8.83333H12.8334C13.1096 8.83333 13.3334 8.60947 13.3334 8.33333C13.3334 8.05719 13.1096 7.83333 12.8334 7.83333V7.83333Z" />
      <path d="M12.0001 9.83333H9.66675C9.39061 9.83333 9.16675 10.0572 9.16675 10.3333C9.16675 10.6095 9.39061 10.8333 9.66675 10.8333H12.0001C12.2762 10.8333 12.5001 10.6095 12.5001 10.3333C12.5001 10.0572 12.2762 9.83333 12.0001 9.83333V9.83333Z" />
    </SvgIcon>
  );
};

export default InsuranceCard;
