import { Helmet } from 'react-helmet';
import { useSupport } from '../useSupport';

const rocketChatDomain = 'rocketchat.internals.silbo.com';

export const RocketChat = () => {
  const { isChatSupportEnabled } = useSupport();

  if (!isChatSupportEnabled) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`
            (function (w, d, s, u) {
              w.RocketChat = function (c) {
                w.RocketChat._.push(c);
              };
              w.RocketChat._ = [];
              w.RocketChat.url = u;
              var h = d.getElementsByTagName(s)[0],
                j = d.createElement(s);
              j.async = true;
              j.src = 'https://${rocketChatDomain}/livechat/rocketchat-livechat.min.js?_=201903270000';
              h.parentNode.insertBefore(j, h);
            })(window, document, 'script', 'https://${rocketChatDomain}/livechat');
            `}
      </script>
    </Helmet>
  );
};
