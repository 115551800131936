import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { palette } from 'common/theme';
import { ContentLoader } from 'core/layout';
import React from 'react';
import useHealthCareEnvironmentalCleaning from 'superadmin/hooks/useHealthCareEnvironmentalCleaning';
import HealthcareEnvironmentalCleaningListItem from './HealthcareEnvironmentalCleaningListItem';
import { useAuthentication } from 'core/authentication';

const HealthcareEnvironmentalCleaningList = () => {
  const { healthCenter } = useAuthentication();
  const { cleaningTasks, loading } = useHealthCareEnvironmentalCleaning({ healthCenterId: healthCenter.id });

  if (loading) {
    return <ContentLoader />;
  }

  return (
    <Stack paddingY={4}>
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{ color: palette.text.primary, paddingLeft: 1 }}>Référence</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell sx={{ color: palette.text.primary }}>Localisation</TableCell>
                <TableCell>Date de début</TableCell>
                <TableCell>Date de fin</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cleaningTasks.map((task) => (
                /* @ts-ignore */
                <HealthcareEnvironmentalCleaningListItem
                  externalId={task.externalId}
                  startAt={task.startAt}
                  endAt={task.endAt}
                  status={task.status}
                  location={task.location}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
};

export default HealthcareEnvironmentalCleaningList;
