import Api, {
  Accommodation,
  AccommodationRequest,
  AccommodationSpecificity,
  Patient,
  PatientGender,
  PerformerRequest,
  Unit,
} from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { Order } from 'common/types';
import { filterBySearch } from 'common/utils';
import { startOfDay, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { comparePatients, compareValues, getCurrentPerformerRequest, useEveryMinutes } from '../utils';

export type HospitalizationRequestStatus = 'pending' | 'accepted' | 'rejected' | 'admitted' | 'to_address';

export type HospitalizationRequest = {
  id: string;
  status: HospitalizationRequestStatus;
  accommodationRequest: AccommodationRequest;
  currentPerformerRequest?: PerformerRequest;
  origin: Accommodation;
  destination?: Accommodation;
  patient: Patient;
};

export type HospitalizationRequestsOrderBy = 'startAt' | 'patient' | 'age' | 'status' | 'destination' | 'bed';

export type HospitalizationRequestsOrderParams = {
  orderBy: HospitalizationRequestsOrderBy;
  order: Order;
};

type FilterParams = {
  search?: string;
  specificities?: AccommodationSpecificity[];
  genders?: PatientGender[];
};

type PaginationParams = {
  page: number;
  rowsPerPage: number;
};

type UseHospitalizationRequestParams = HospitalizationRequestsOrderParams & FilterParams & PaginationParams;

const useHospitalizationRequests = (
  unitId: string,
  { orderBy, order, search, specificities = [], genders = [], page, rowsPerPage }: UseHospitalizationRequestParams
) => {
  const now = useEveryMinutes();

  const [hospitalizationRequests, setHospitalizationRequests] = useState<HospitalizationRequest[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const { results: inProgressAccommodations, isLoading: inProgressAccommodationsLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .equalTo('unit', Unit.createWithoutData(unitId))
      .equalTo('status', 'IN_PROGRESS')
      .greaterThanOrEqualTo('startAt', startOfDay(subDays(now, 15)))
      .lessThanOrEqualTo('startAt', now)
      .include('bed')
      .exists('visit')
  );

  const visits = inProgressAccommodations.map((accommodation) => accommodation.visit) as Patient[];

  const {
    results: accommodationRequests,
    isLoading: accommodationRequestsLoading,
    isEnabled: accommodationRequestsEnabled,
  } = useParseQuery(
    new Api.Query(AccommodationRequest)
      .containedIn('visit', visits)
      .include('visit', 'performerRequests', 'performerRequests.performer', 'thesaurusItem.redirection'),
    { enabled: !inProgressAccommodationsLoading }
  );

  const {
    results: nextAccommodations,
    isLoading: nextAccommodationsLoading,
    isEnabled: nextAccommodationsEnabled,
  } = useParseQuery(
    new Api.Query(Accommodation).containedIn('basedOn', accommodationRequests).include('unit', 'bed', 'bedHistory.bed'),
    {
      enabled: !accommodationRequestsLoading,
    }
  );

  const isLoading = inProgressAccommodationsLoading || accommodationRequestsLoading || nextAccommodationsLoading;
  const isEnabled = accommodationRequestsEnabled && nextAccommodationsEnabled;
  const hasFetched = isEnabled && !isLoading;

  useEffect(() => {
    if (!hasFetched) {
      return;
    }
    let hospitalizationRequests: HospitalizationRequest[] = [];

    for (const accommodationRequest of accommodationRequests) {
      const destination = nextAccommodations.find(({ basedOn }) => basedOn?.id === accommodationRequest.id);
      const currentPerformerRequest = getCurrentPerformerRequest(accommodationRequest.performerRequests || []);
      const origin = inProgressAccommodations.find(({ visit }) => visit?.id === accommodationRequest.visit?.id);

      if (
        destination?.status !== 'COMPLETED' &&
        currentPerformerRequest?.performer.id !== unitId &&
        origin &&
        accommodationRequest.visit
      ) {
        hospitalizationRequests.push({
          id: accommodationRequest.id,
          status: getHospitalizationRequestStatus({ destination, accommodationRequest }),
          accommodationRequest,
          destination,
          origin,
          currentPerformerRequest: currentPerformerRequest,
          patient: accommodationRequest.visit,
        });
      }
    }

    hospitalizationRequests = filterResults(hospitalizationRequests, { search, specificities, genders });

    hospitalizationRequests = sortResults(hospitalizationRequests, { orderBy, order });

    setTotalCount(hospitalizationRequests.length);
    setHospitalizationRequests(hospitalizationRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    setLoading(false);
  }, [
    accommodationRequests,
    search,
    genders,
    nextAccommodations,
    order,
    orderBy,
    page,
    rowsPerPage,
    specificities,
    unitId,
    inProgressAccommodations,
    hasFetched,
  ]);

  return {
    hospitalizationRequests,
    totalCount,
    loading,
  };
};

const sortResults = (results: HospitalizationRequest[], { orderBy, order }: HospitalizationRequestsOrderParams) => {
  return results.sort((a, b) => {
    switch (orderBy) {
      case 'startAt':
        return compareValues(a.destination?.startAt, b.destination?.startAt, order);
      case 'patient':
        return comparePatients(a.patient, b.patient, order);
      case 'age':
        return compareValues(a.patient.birthdate, b.patient.birthdate, order === 'asc' ? 'desc' : 'asc');
      case 'status':
        return compareValues(a.status, b.status, order);
      case 'destination':
        return compareValues(
          a.destination?.unit?.name || a.currentPerformerRequest?.performer?.name,
          b.destination?.unit?.name || b.currentPerformerRequest?.performer?.name,
          order
        );
      case 'bed':
        return compareValues(a.origin.bed?.name, b.origin.bed?.name, order);
      default:
        return 0;
    }
  });
};

const filterResults = (results: HospitalizationRequest[], { search, specificities, genders }: FilterParams) => {
  const presentPatients: HospitalizationRequest[] = [];

  if (search && search.length > 0) {
    results = filterBySearch({
      search,
      list: results,
      keys: ['patient.firstName', 'patient.lastName', 'patient.legalName', 'patient.legalFirstName'],
      minScore: 0.5,
    });
  }

  for (const hospitalizationRequest of results) {
    if (specificities && specificities.length > 0) {
      if (
        !specificities.some((specificity) =>
          hospitalizationRequest.accommodationRequest.specificities?.includes(specificity)
        )
      ) {
        continue;
      }
    }
    if (genders && genders.length > 0) {
      if (!genders.includes(hospitalizationRequest.patient.gender)) {
        continue;
      }
    }
    presentPatients.push(hospitalizationRequest);
  }

  return presentPatients;
};

export const getHospitalizationRequestStatus = ({
  destination,
  accommodationRequest,
}: {
  destination?: Accommodation;
  accommodationRequest: AccommodationRequest;
}): HospitalizationRequestStatus => {
  switch (accommodationRequest?.status) {
    case 'ACCEPTED':
      return destination?.bed ? 'admitted' : 'accepted';

    case 'REJECTED':
      return 'rejected';

    case 'REQUESTED':
      return accommodationRequest.performerRequests && accommodationRequest.performerRequests.length > 0
        ? 'pending'
        : 'to_address';

    default:
      return 'pending';
  }
};

export default useHospitalizationRequests;
