import { HealthCenter, ThesaurusItem } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

export const useThesaurusItems = (healthCenterId: string, enabled: boolean = true) => {
  const response = useParseQuery(
    new Parse.Query(ThesaurusItem)
      .equalTo('healthCenter', HealthCenter.createWithoutData(healthCenterId))
      .include('redirection'),
    { enabled }
  );

  response.results.sort((a, b) => a.reason.localeCompare(b.reason));
  return response;
};
