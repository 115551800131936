import Api, {
  AccommodationRequest,
  Accommodation as ParseAccommodation,
  PerformerRequest,
  Unit,
} from '@ambuliz/sabri-core';
import { formatFirstname, formatName } from 'common/utils';
import { liveQueryClient } from 'core/live-query-client';
import { useEffect, useState } from 'react';
import { Accommodation, mapAccommodation } from './useMovementAccommodations';

type AccommodationFilter = { unitId: string; flow: 'admission' | 'discharge' };

const useAccommodationRequests = ({ unitId, flow }: AccommodationFilter) => {
  const [accommodationsWithRequest, setAccommodationsWithRequest] = useState<Accommodation[]>([]);
  const [requestsForUnit, setRequestsForUnit] = useState<Accommodation[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let liveSubscription: Parse.LiveQuerySubscription;

    const fetchAccommodations = async () => {
      if (flow === 'admission') {
        setRequestsForUnit(await getPerformersRequests(unitId));
      } else {
        setAccommodationsWithRequest(await getAccommodationsRequests(unitId));
      }
    };

    const fetch = async () => {
      setLoading(true);

      liveSubscription = liveQueryClient.subscribe(
        new Api.Query(PerformerRequest),
        Parse.User.current()?.getSessionToken()
      );

      liveSubscription.on('open', async () => {
        await fetchAccommodations();
        setLoading(false);
      });

      liveSubscription.on('create', fetchAccommodations);
      liveSubscription.on('enter', fetchAccommodations);
      liveSubscription.on('leave', fetchAccommodations);
      liveSubscription.on('update', fetchAccommodations);
    };

    fetch();

    return () => liveQueryClient.unsubscribe(liveSubscription);
  }, [unitId, flow]);

  return {
    loading,
    accommodationsWithRequest,
    requestsForUnit,
  };
};

const getPerformersRequests = async (unitId: string) => {
  const performersRequests = await new Api.Query(PerformerRequest)
    .equalTo('status', 'REQUESTED')
    .equalTo('performer', Unit.createWithoutData(unitId))
    .include('request', 'request.createdBy', 'request.visit')
    .select('status', 'request', 'performer.name')
    .findAll();

  const linkedVisits = performersRequests.map(({ request }) => request.visit);

  const parseAccommodations = await new Api.Query(ParseAccommodation)
    .equalTo('status', 'IN_PROGRESS')
    .containedIn('visit', linkedVisits)
    .include('visit', 'unit.name', 'bed.name')
    .findAll();

  const accommodations: Accommodation[] = [];

  for (const performerRequest of performersRequests) {
    const request = performerRequest.request;
    const previousAccommodation = parseAccommodations.find(
      (accommodation) => accommodation?.visit?.id === performerRequest.request.visit?.id
    );
    const visit = previousAccommodation?.visit || request.visit;

    const name = `${visit?.lastName?.toUpperCase()} ${formatFirstname(visit?.firstName)}`;
    const fullName = formatName(visit?.firstName, visit?.lastName, visit?.legalName, visit?.legalFirstName);

    if (visit) {
      const acc: Accommodation = {
        id: performerRequest.id,
        status: 'PLANNED',
        createdAt: performerRequest.createdAt,
        pan: visit?.pan || '',
        specificities: [],
        unit: {
          id: performerRequest.performer.id,
          name: performerRequest.performer.name,
        },
        basedOn: {
          id: request.id,
          reason: request.reason,
          status: request.status,
          performerRequests: [performerRequest],
          specificities: request.specificities,
          comment: request.comment,
          createdAt: request.createdAt,
          directAdmissionOrigin: request.directAdmissionOrigin,
          requestType: request.requestType,
          thesaurusItem: request.thesaurusItem,
        },
        comment: request.comment,
        patient: {
          id: visit!.id,
          ipp: visit!.ipp,
          ins: visit!.ins,
          name,
          fullName,
          gender: visit!.gender,
          birthdate: visit!.birthdate,
        },
        previousAccommodation: previousAccommodation ? mapAccommodation(previousAccommodation) : undefined,
        isMutationRequest: true,
      };
      accommodations.push(acc);
    }
  }

  return accommodations;
};

const getAccommodationsRequests = async (unitId: string) => {
  const statuses = ['REQUESTED', 'REJECTED'];

  // Retrieve every opened mutation queries
  const requests = await new Api.Query(AccommodationRequest)
    .containedIn('status', statuses)
    .exists('performerRequests')
    .include('performerRequests', 'performerRequests.createdBy', 'performerRequests.fulfilledBy', 'thesaurusItem.redirection')
    .select(
      'performerRequests.status',
      'performerRequests.rejectionReason',
      'performerRequests.performer.name',
      'performerRequests.fulfilledAt',
      'performerRequests.fulfilledBy',
      'performerRequests.createdBy',
      'visit.objectId',
      'status',
      'specificities',
      'comment',
      'reason',
      'thesaurusItem',
    )
    .findAll();

  const linkedVisits = requests.map(({ visit }) => visit);

  // From that point we use the related visits to find steps that match
  const parseAccommodations = await new Api.Query(ParseAccommodation)
    .equalTo('unit', Unit.createWithoutData(unitId))
    .equalTo('status', 'IN_PROGRESS')
    .containedIn('visit', linkedVisits)
    .include('visit', 'unit.name', 'bed.name')
    .findAll();

  // Now we have to establish the relationship between the found requets and the accommodations
  const accommodations: Accommodation[] = [];

  for (const request of requests) {
    const currentAccommodation = parseAccommodations.find(
      (accommodation) => accommodation?.unit.id === unitId && accommodation?.visit?.id === request.visit?.id
    );

    if (currentAccommodation) {
      currentAccommodation.basedOn = request;

      const mappedAccommodation = mapAccommodation(currentAccommodation);

      if (mappedAccommodation) {
        const hasRejection = request.performerRequests?.filter((request) => request.status === 'REJECTED');
        mappedAccommodation.isMutationRequest = true;
        mappedAccommodation.numberOfRejections = hasRejection?.length || 0;
        mappedAccommodation.isRejected =
          mappedAccommodation.numberOfRejections > 0 && hasRejection?.length === request.performerRequests?.length;

        mappedAccommodation.basedOn = {
          reason: currentAccommodation.basedOn?.reason,
          id: currentAccommodation.basedOn?.id,
          status: currentAccommodation.basedOn?.status,
          performerRequests: currentAccommodation.basedOn?.performerRequests,
          createdAt: currentAccommodation.basedOn?.createdAt,
          specificities: currentAccommodation.basedOn?.specificities,
          comment: currentAccommodation.basedOn?.comment,
          directAdmissionOrigin: currentAccommodation.basedOn?.directAdmissionOrigin,
          requestType: currentAccommodation.basedOn?.requestType,
          thesaurusItem: currentAccommodation.basedOn?.thesaurusItem,
        };

        accommodations.push(mappedAccommodation);
      }
    }
  }

  return accommodations;
};

export default useAccommodationRequests;
