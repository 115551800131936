import { ThesaurusItem, Unit } from '@ambuliz/sabri-core';
import { Chip, Stack, Typography } from '@mui/material';
import CheckFilledIcon from 'common/components/Icons/CheckFilledIcon';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import shadows from 'common/theme/shadows';
import { useCallback } from 'react';

type ThesaurusUnitsSuggestionsProps = {
  thesaurusItem: ThesaurusItem;
  selectedUnit?: string;
  disabledUnits?: string[];
  disabled?: boolean;
  onChange?: (unitId: string) => void;
};

export const ThesaurusUnitsSuggestions = ({
  thesaurusItem,
  selectedUnit,
  disabledUnits,
  disabled,
  onChange,
}: ThesaurusUnitsSuggestionsProps) => {
  const isUnitSelected = useCallback((unit: Unit) => unit.id === selectedUnit, [selectedUnit]);
  const isDisabled = useCallback(
    (unit: Unit) => disabled || disabledUnits?.includes(unit.id),
    [disabled, disabledUnits]
  );

  if (!thesaurusItem.redirection?.length) {
    return null;
  }
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle2" lineHeight="12px">
          {i18n.thesaurusSuggestionsOf}
        </Typography>
        <Chip
          label={i18n.thesaurus.toUpperCase()}
          variant="outlined"
          size="small"
          color="info"
          sx={{
            '& .MuiChip-label': {
              paddingLeft: '6px',
              paddingRight: '6px',
            },
          }}
        />
        <Typography variant="subtitle2" lineHeight="12px">
          :
        </Typography>
      </Stack>
      <Stack spacing={2}>
        {thesaurusItem.redirection?.map((unit, index) => (
          <Stack
            key={unit.id}
            direction="row"
            spacing={2}
            padding={3}
            border="solid 1px"
            borderRadius={1}
            borderColor={isUnitSelected(unit) ? 'primary.main' : color.grey[10]}
            bgcolor={isUnitSelected(unit) ? 'primary.light' : undefined}
            justifyContent="space-between"
            alignItems="center"
            boxShadow={shadows[1]}
            sx={{
              cursor: !isDisabled(unit) ? 'pointer' : 'not-allowed',
              opacity: isDisabled(unit) ? 0.4 : undefined,
              '&:hover': {
                bgcolor: 'primary.light',
              },
            }}
            onClick={() => !isDisabled(unit) && onChange?.(unit.id)}
          >
            <Typography variant="body2" fontWeight={600} color={isUnitSelected(unit) ? 'primary' : undefined}>
              {index + 1}.
            </Typography>
            <Typography
              variant="body2"
              fontWeight={600}
              color={isUnitSelected(unit) ? 'primary' : undefined}
              width="100%"
            >
              {unit.name}
            </Typography>
            {isUnitSelected(unit) && <CheckFilledIcon color="primary" />}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
