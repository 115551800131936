import { SvgIcon, SvgIconProps } from '@mui/material';

export const IsolatedAirIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.16455 8.06446C0.16455 7.71928 0.444372 7.43946 0.78955 7.43946L12.5979 7.43946L12.6124 7.43962C14.3937 7.44741 15.8354 8.8939 15.8354 10.6771C15.8354 11.9507 15.0999 13.0514 14.0333 13.5797C13.724 13.7329 13.3491 13.6064 13.1959 13.297C13.0427 12.9877 13.1692 12.6128 13.4785 12.4596C14.1358 12.134 14.5854 11.4575 14.5854 10.6771C14.5854 9.57944 13.6956 8.68959 12.5979 8.68959L12.5849 8.68946L0.78955 8.68946C0.444372 8.68946 0.16455 8.40963 0.16455 8.06446ZM2.19879 10.3826C1.85361 10.3826 1.57379 10.6624 1.57379 11.0076C1.57379 11.3527 1.85361 11.6326 2.19879 11.6326L7.25588 11.6326L7.25706 11.6326C8.04935 11.6332 8.69142 12.2757 8.69142 13.0681C8.69142 13.8609 8.04871 14.5037 7.25588 14.5037C6.98606 14.5037 6.7354 14.4298 6.52089 14.3016C6.2246 14.1245 5.84085 14.2211 5.66376 14.5174C5.48666 14.8137 5.58329 15.1975 5.87957 15.3745C6.28254 15.6154 6.75402 15.7537 7.25588 15.7537C8.73907 15.7537 9.94142 14.5513 9.94142 13.0681C9.94142 11.5972 8.75891 10.4025 7.2927 10.3828L7.27496 10.3826L7.25749 10.3826L7.25588 10.3826L7.2551 10.3826L2.19879 10.3826ZM1.57379 4.99243C1.57379 4.64725 1.85361 4.36743 2.19879 4.36743L10.3506 4.36743L10.3506 4.36743C11.1434 4.36743 11.7861 3.72472 11.7861 2.93189C11.7861 2.13906 11.1434 1.49634 10.3506 1.49634C10.0089 1.49634 9.69666 1.61492 9.45023 1.8137C9.18157 2.03042 8.78809 1.98832 8.57136 1.71966C8.35464 1.45099 8.39675 1.05751 8.66541 0.840789C9.12603 0.469228 9.71313 0.24634 10.3506 0.24634C11.8338 0.24634 13.0361 1.4487 13.0361 2.93189C13.0361 4.41507 11.8338 5.61743 10.3506 5.61743L10.3506 5.61743L2.19879 5.61743C1.85361 5.61743 1.57379 5.33761 1.57379 4.99243Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);
