import { PageHeader, PageSection } from 'core/layout';
import { useState } from 'react';
import { useThesaurusPaginated } from './useThesaurusPaginated';
import { ThesaurusTable } from 'superadmin/components/ThesaurusTable/ThesaurusTable';
import { Button } from '@mui/material';
import UploadCSVDialog from './UploadCSVDialog';
import { Upload } from '@mui/icons-material';
import { i18n } from 'common/locale';

const Thesaurus = () => {
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { thesaurus, isLoading, count, refetch } = useThesaurusPaginated({ page, rowsPerPage });

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleImportSuccess = () => {
    refetch();
  };

  return (
    <>
      <PageHeader title={i18n.thesaurus}>
        <Button
          variant="contained"
          startIcon={<Upload />}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Importer le Thésaurus
        </Button>
        <UploadCSVDialog open={openDialog} onClose={handleCloseDialog} onSuccess={handleImportSuccess} />
      </PageHeader>

      <PageSection lastSection>
        <ThesaurusTable
          thesaurus={thesaurus}
          count={count}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
        />
      </PageSection>
    </>
  );
};

export default Thesaurus;
