import { ThesaurusItem } from '@ambuliz/sabri-core';
import { TableCell, TableRow } from '@mui/material';

type ThesaurusTableRowProps = {
  thesaurusItem: ThesaurusItem;
};

const convertMinutesToDays = (minutes: number) => {
  return minutes / (60 * 24);
};

export const ThesaurusTableRow = ({
  thesaurusItem: { reason, expectedDurationInMinutes, redirection },
}: ThesaurusTableRowProps) => {
  return (
    <TableRow>
      <TableCell>{reason}</TableCell>
      <TableCell>{expectedDurationInMinutes ? convertMinutesToDays(expectedDurationInMinutes) : '-'}</TableCell>
      {new Array(3).fill(undefined).map((value, index) => (
        <TableCell>{redirection?.length && redirection[index] ? redirection[index].name : '-'}</TableCell>
      ))}
    </TableRow>
  );
};
