import { ThesaurusItem } from '@ambuliz/sabri-core';
import { Typography } from '@mui/material';
import { ThesaurusItemCard } from 'common/components/ThesaurusReasonAutocomplete/ThesaurusItemCard';
import { ThesaurusReasonAutocomplete } from 'common/components/ThesaurusReasonAutocomplete/ThesaurusReasonAutocomplete';
import { UserHealthCenter } from 'core/authentication';
import { AccommodationStep, MutationStep } from '../context/Step';

export const EditableStepReason = ({
  healthcenter,
  step,
  editable,
  onChange,
}: {
  healthcenter: UserHealthCenter;
  step: AccommodationStep | MutationStep;
  editable?: boolean;
  onChange: (value: ThesaurusItem | string | null) => void;
}) => {
  if (editable) {
    return <ThesaurusReasonAutocomplete onChange={onChange} value={step.step.thesaurusItem || step.step.reason} />;
  }

  if (healthcenter?.thesaurusReasonEnabled && step.step.reason) {
    return <ThesaurusItemCard variant="filled" thesaurus={step.step.thesaurusItem || step.step.reason} />;
  }
  return (
    <Typography variant="body2" whiteSpace="pre-line" padding={2}>
      {step.step.reason}
    </Typography>
  );
};
