import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import ActionButton from 'common/components/ActionButton';
import EmergencyIcon from 'common/components/Icons/Emergency';
import { formatAge, getPatientAge, getPatientName } from 'common/utils';
import { AccommodationSpecificityBadges } from 'kurt/components';
import ReportPatientDischargedDialog from 'kurt/components/ReportPatientDischarged';
import { MouseEvent, useEffect, useState } from 'react';
import {
  AcceptPerformerRequestDialog,
  CancelAccommodationRequestDialog,
  CreateNewPerformerRequestDialog,
  RejectPerformerRequestDialog,
  UpdateAccommodationStartDateDialog,
} from '../actions';
import { HospitalizationRequestActionMenu } from './HospitalizationRequestActionMenu';
import { HospitalizationRequest } from './useHospitalizationRequests';

import EllipsisTypography from 'common/components/EllipsisTypography';
import CheckIcon from 'common/components/Icons/Check';
import CrossIcon from 'common/components/Icons/Cross';
import ExitIcon from 'common/components/Icons/Exit';
import { AccommodationBedHistory } from 'kurt/components/AccommodationBedHistory/AccommodationBedHistory';
import { AccommodationStartAtHistory } from 'kurt/components/AccommodationStartAtHistory/AccommodationStartAtHistory';
import { useAccommodationRequest } from 'kurt/hooks';
import EditableTableCell from '../../../components/EditableTableCell/EditableTableCell';
import { AddressMutationRequestDialog } from '../actions/AddressMutationRequestDialog';
import HospitalizationRequestStatusChip from './HospitalizationRequestStatusChip';
import { HISTORY_CELLS_PADDING_LEFT } from './HospitalizationsTable';

export type HospitalizationRequestAction =
  | 'accept_request'
  | 'reject_request'
  | 'delete_request'
  | 'create_new_request'
  | 'update_request_date'
  | 'discharge_patient'
  | 'address_request'
  | null;

type HospitalizationsTableRowProps = {
  hospitalization: HospitalizationRequest;
  isReadOnly?: boolean;
  onClick?: () => void;
};

const HospitalizationsTableRow = ({ hospitalization, isReadOnly = false, onClick }: HospitalizationsTableRowProps) => {
  const { patient, destination, origin, accommodationRequest, status, currentPerformerRequest } = hospitalization;

  const [activeAction, setActiveAction] = useState<HospitalizationRequestAction>(null);

  const [reason, setReason] = useState(accommodationRequest.reason || '');
  const [comment, setComment] = useState(accommodationRequest.comment || '');

  useEffect(() => setReason(accommodationRequest.reason || ''), [accommodationRequest.reason]);
  useEffect(() => setComment(accommodationRequest.comment || ''), [accommodationRequest.comment]);
  const { update, loading } = useAccommodationRequest(accommodationRequest.id);

  const handleActionClick = (action: HospitalizationRequestAction) => {
    setActiveAction(action);
  };

  const handleQuickActionClick = (action: HospitalizationRequestAction) => (event: MouseEvent<Element>) => {
    event.stopPropagation();
    setActiveAction(action);
  };

  const handleCloseDialog = () => {
    setActiveAction(null);
  };

  const handleReasonSubmit = async (value: string) => {
    if (value !== accommodationRequest.reason) {
      await update({
        id: accommodationRequest.id,
        reason: value,
      });
    }
  };

  const handleCommentSubmit = async (value: string) => {
    if (value !== accommodationRequest.comment) {
      await update({
        id: accommodationRequest.id,
        comment: value,
      });
    }
  };

  const destinationUnitName = destination?.unit?.name || currentPerformerRequest?.performer?.name;
  const isToAddress = accommodationRequest.status === 'REQUESTED' && !accommodationRequest.performerRequests?.length;

  return (
    <>
      <TableRow hover sx={{ cursor: 'pointer' }} onClick={onClick}>
        <TableCell>
          <EllipsisTypography tooltipProps={{ title: 'Lit ' + origin?.bed?.name || '-' }} fontWeight={600}>
            {origin?.bed?.name || '-'}
          </EllipsisTypography>
        </TableCell>
        <TableCell>
          <PatientNameWithEmoji
            age={getPatientAge(patient.birthdate)}
            name={getPatientName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName)}
            gender={patient.gender}
            bgcolor="primary.light"
          />
        </TableCell>
        <TableCell>
          <Typography color="secondary" variant="body2" noWrap>
            {formatAge(patient.birthdate)}
          </Typography>
        </TableCell>
        <TableCell>
          <HospitalizationRequestStatusChip status={status} />
        </TableCell>

        <TableCell>
          {destinationUnitName && (
            <Stack spacing={1} direction="row" alignItems="center" maxWidth={200}>
              <EmergencyIcon color="danger" />
              <Box textOverflow="ellipsis" overflow="hidden" paddingLeft={2}>
                <Typography variant="body2" color="secondary" noWrap>
                  {destinationUnitName}
                </Typography>
              </Box>
              <AccommodationBedHistory
                accommodation={destination}
                chipProps={{
                  sx: {
                    marginLeft: HISTORY_CELLS_PADDING_LEFT,
                  },
                }}
              />
            </Stack>
          )}
        </TableCell>
        <TableCell>
          <AccommodationStartAtHistory
            accommodation={destination}
            typographyProps={{
              marginLeft: HISTORY_CELLS_PADDING_LEFT,
            }}
          />
        </TableCell>

        <EditableTableCell
          value={reason}
          onChange={setReason}
          onSubmit={handleReasonSubmit}
          loading={loading && reason !== accommodationRequest.reason}
          isReadOnly={isReadOnly}
        />
        <TableCell>
          <Box display="flex" sx={{ cursor: 'pointer' }}>
            <AccommodationSpecificityBadges
              specificities={accommodationRequest.specificities || destination?.specificities || []}
            />
          </Box>
        </TableCell>
        <EditableTableCell
          value={comment}
          onChange={setComment}
          onSubmit={handleCommentSubmit}
          loading={loading && comment !== accommodationRequest.comment}
          isReadOnly={isReadOnly}
        />
        {!isReadOnly && (
          <>
            <TableCell>
              <Stack spacing={2} direction="row" alignItems="center">
                {status === 'pending' && (
                  <>
                    <ActionButton icon={<CheckIcon />} onClick={handleQuickActionClick('accept_request')} />
                    <ActionButton icon={<CrossIcon />} onClick={handleQuickActionClick('reject_request')} />
                  </>
                )}
                {status === 'admitted' && (
                  <ActionButton icon={<ExitIcon />} onClick={handleQuickActionClick('discharge_patient')} />
                )}
              </Stack>
            </TableCell>
            <TableCell>
              <HospitalizationRequestActionMenu onActionClick={handleActionClick} status={status} />
            </TableCell>
          </>
        )}
      </TableRow>

      {isToAddress && (
        <AddressMutationRequestDialog
          accommodationRequest={accommodationRequest}
          onClose={handleCloseDialog}
          open={activeAction === 'address_request'}
        />
      )}

      {(currentPerformerRequest?.status === 'ACCEPTED' || currentPerformerRequest?.status === 'REQUESTED') && (
        <RejectPerformerRequestDialog
          performerRequestId={currentPerformerRequest.id}
          open={activeAction === 'reject_request'}
          onClose={handleCloseDialog}
        />
      )}

      {currentPerformerRequest?.status === 'REQUESTED' && (
        <AcceptPerformerRequestDialog
          open={activeAction === 'accept_request'}
          onClose={handleCloseDialog}
          performerRequestId={currentPerformerRequest.id}
        />
      )}

      <CancelAccommodationRequestDialog
        accommodationRequestId={accommodationRequest.id}
        open={activeAction === 'delete_request'}
        onClose={handleCloseDialog}
      />

      <CreateNewPerformerRequestDialog
        open={activeAction === 'create_new_request'}
        onClose={handleCloseDialog}
        accommodationRequest={accommodationRequest}
      />

      {!!destination?.id && (
        <>
          <UpdateAccommodationStartDateDialog
            open={activeAction === 'update_request_date'}
            accommodationId={destination.id}
            onClose={handleCloseDialog}
          />
        </>
      )}

      {!!origin?.id && (
        <ReportPatientDischargedDialog
          id={origin.id}
          open={activeAction === 'discharge_patient'}
          onClose={handleCloseDialog}
          onSuccess={handleCloseDialog}
        />
      )}
    </>
  );
};

export default HospitalizationsTableRow;
