import { liveQueryClient } from 'core/live-query-client';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface ParseQueryOptions {
  enabled?: boolean;
  enableLocalDatastore?: boolean;
  enableLiveQuery?: boolean;
}

const FETCH_DELAY = 1000;

export const useParseQuery = <T extends Parse.Object>(query: Parse.Query<T> | null, options?: ParseQueryOptions) => {
  const { enabled = !!query, enableLiveQuery = true } = options || {};

  const [internalLoading, setInternalLoading] = useState(enabled);
  const [isEnabled, setEnabled] = useState(enabled);
  const [isLive, setLive] = useState(false);
  const isSyncing = false;
  const [entities, setEntities] = useState<Record<string, T>>({});

  // We set the loading state to true synchronously with the change of enabled
  const isLoading = useMemo(() => {
    if (enabled && !isEnabled) {
      return true;
    }
    if (isEnabled) {
      return internalLoading;
    }
    return undefined;
  }, [internalLoading, isEnabled, enabled]);

  const className = query ? query.className : undefined;
  const queryAsString = query ? JSON.stringify(query) : undefined;

  const includeAsString = query ? JSON.stringify((query as any)._include) : undefined;
  const include = useMemo(() => (includeAsString ? JSON.parse(includeAsString) : []) as string[], [includeAsString]);

  const fetch = useCallback(
    async <T extends Parse.Object>(query: Parse.Query<T>) => {
      //@ts-expect-error
      const hasLimit = query._limit > 0;
      try {
        const entities = hasLimit ? await query.find() : await query.findAll({ batchSize: 500 });
        setEntities(entities.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {}));
      } catch (error) {
        console.error(error);
        if (error.statusCode === 429) {
          window.localStorage.removeItem('Parse/sabri/installationId');
          window.localStorage.removeItem('Parse/sabri/currentUser');
          window.location.reload();
        }
      }
      setInternalLoading(false);
    },
    [setInternalLoading, setEntities]
  );

  const refetch = useCallback(async () => {
    if (className && queryAsString) {
      const query = Parse.Query.fromJSON<T>(className, JSON.parse(queryAsString));
      setInternalLoading(true);
      return fetch(query);
    }
  }, [queryAsString, className, fetch]);

  useEffect(() => {
    setInternalLoading(enabled);
    setEnabled(enabled);
  }, [queryAsString, enabled]);

  useEffect(() => {
    let subscription: Parse.LiveQuerySubscription;
    let query: Parse.Query<T>;

    const entitiesToFetch = new Set<string>();
    let fetchTimer: NodeJS.Timeout | undefined = undefined;
    let fetchQuery: Parse.Query<T>;

    const init = async () => {
      setEnabled(true);
      setInternalLoading(true);

      if (className && queryAsString) {
        query = Parse.Query.fromJSON<T>(className, JSON.parse(queryAsString));

        const fetchEntities = async () => {
          fetch(query);
        };

        const handleUpdate = async (entity: Parse.Object) => {
          if (fetchTimer) {
            clearTimeout(fetchTimer);
            fetchTimer = undefined;
          }

          if (include.length > 0) {
            entitiesToFetch.add(entity.id);
            fetchTimer = setTimeout(async () => {
              try {
                if (entitiesToFetch.size === 0) return;

                fetchQuery = new Parse.Query<T>(className)
                  .containedIn('objectId', Array.from(entitiesToFetch) as T['attributes']['objectId'][])
                  .include(include);
                entitiesToFetch.clear();

                const entities = await fetchQuery.findAll();
                const newEntities = entities.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {});
                setEntities((prev) => ({ ...prev, ...newEntities }));
              } catch (error) {
                console.error(error);
              }
            }, FETCH_DELAY);
          } else {
            setEntities((prev) => ({ ...prev, [entity.id]: entity as T }));
          }
        };

        const handleDelete = (entity: Parse.Object) => {
          entitiesToFetch.delete(entity.id);
          setEntities((prev) => {
            const state = { ...prev };
            delete state[entity.id];
            return state;
          });
        };

        try {
          if (enableLiveQuery) {
            subscription = liveQueryClient.subscribe(query, Parse.User.current()?.getSessionToken());

            subscription.on('open', async () => {
              await fetchEntities();
              setLive(true);
            });

            subscription.on('close', () => {
              if (fetchTimer) {
                clearTimeout(fetchTimer);
                fetchTimer = undefined;
              }
              entitiesToFetch.clear();
              setLive(false);
            });

            subscription.on('enter', handleUpdate);
            subscription.on('create', handleUpdate);
            subscription.on('update', handleUpdate);

            subscription.on('delete', handleDelete);
            subscription.on('leave', handleDelete);

            //@ts-ignore
            subscription.on('error', (error) => {
              if (fetchTimer) {
                clearTimeout(fetchTimer);
                fetchTimer = undefined;
              }
              entitiesToFetch.clear();
              setLive(false);
            });
          } else {
            await fetchEntities();
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (enabled) {
      init();
    } else {
      setEntities({});
    }
    return () => {
      query?.cancel();
      fetchQuery?.cancel();
      liveQueryClient.unsubscribe(subscription);
      entitiesToFetch.clear();
      if (fetchTimer) {
        clearTimeout(fetchTimer);
      }
    };
  }, [queryAsString, className, enabled, include, enableLiveQuery, fetch]);

  return {
    results: useMemo(() => Object.values(entities), [entities]),
    isLoading,
    isEnabled,
    isLive,
    isSyncing,
    refetch,
  };
};

export default useParseQuery;
