import { Box } from '@mui/material';
import { color, palette } from 'common/theme';
import shadows from 'common/theme/shadows';
import React, { MutableRefObject, ReactNode, forwardRef, useRef } from 'react';
import styled from 'styled-components';
import useSticky from './useSticky';

type PageSectionProps = {
  withBackground?: boolean;
  noPadding?: boolean;
  paddingTop?: number | string;
  noMargin?: boolean;
  lastSection?: boolean;
  noGutter?: boolean;
  sticky?: Sticky | boolean;
  scrollable?: boolean;
  noPaddingX?: boolean;
  noPaddingY?: boolean;
  children?: ReactNode;
  flex?: boolean;
  fullHeight?: boolean;
};

type Sticky = {
  top: number;
  withShadow?: boolean;
};

const Container = styled(Box)`
  &:last-child {
    flex: 1;
  }
`;

const PageSection = React.forwardRef<HTMLDivElement, PageSectionProps>(
  (
    {
      children,
      withBackground = false,
      noGutter = false,
      sticky,
      noPadding,
      paddingTop,
      noMargin,
      lastSection,
      noPaddingY,
      noPaddingX,
      scrollable = false,
      flex = false,
      fullHeight = false,
    },
    ref
  ) => {
    if (withBackground && !noGutter) {
      return (
        <Box
          ref={ref}
          paddingX={10}
          left={0}
          width="100%"
          position="sticky"
          paddingBottom={lastSection ? 15 : undefined}
          marginTop={6}
        >
          <Box bgcolor="background.default" borderRadius={2} padding={3}>
            {children}
          </Box>
        </Box>
      );
    }

    return sticky ? (
      <StickySection
        top={typeof sticky !== 'boolean' ? sticky.top || 0 : 0}
        withShadow={typeof sticky !== 'boolean' ? sticky.withShadow : false}
        noPadding={noPadding}
        noMargin={noMargin}
        lastSection={lastSection}
        noPaddingX={noPaddingX}
        noPaddingY={noPaddingY}
        paddingTop={paddingTop}
        scrollable={scrollable}
        withBackground={withBackground}
        noGutter={noGutter}
        children={children}
        ref={ref}
      />
    ) : (
      <Container
        ref={ref}
        marginTop={noMargin ? undefined : 3}
        paddingY={noPadding || noPaddingY ? undefined : 3}
        paddingTop={!noPadding && !noPaddingY ? paddingTop : undefined}
        paddingBottom={lastSection ? 15 : undefined}
        boxSizing="border-box"
        bgcolor={withBackground ? 'background.default' : 'white'}
        position={scrollable ? 'inherit' : 'sticky'}
        zIndex={sticky ? 10 : undefined}
        left={0}
        paddingX={noPadding || noPaddingX ? undefined : 10}
        width={scrollable ? undefined : '100%'}
        minWidth={scrollable ? '100%' : undefined}
        alignSelf={scrollable ? 'flex-start' : undefined}
        display={flex ? 'flex' : undefined}
        flexGrow={fullHeight ? 1 : undefined}
      >
        {children}
      </Container>
    );
  }
);

const StickySection = forwardRef<
  HTMLDivElement,
  Omit<PageSectionProps, 'sticky'> & { top: number; withShadow?: boolean }
>(
  (
    {
      top,
      withShadow,
      children,
      withBackground = false,
      noPadding,
      noMargin,
      lastSection,
      noPaddingY,
      noPaddingX,
      paddingTop,
      scrollable = false,
    },
    ref
  ) => {
    const newRef = useRef<HTMLDivElement>(null);
    const isSticky = useSticky(top, newRef);

    return (
      <StickyContainer
        style={{
          marginTop: noMargin ? undefined : 12,
          padding: `${noPaddingY || noPadding ? 0 : 12}px ${noPaddingX || noPadding ? 0 : 40}px`,
          paddingTop: `${!noPadding && !noPaddingY ? paddingTop : undefined}px`,
          paddingBottom: `${lastSection ? 15 : undefined}px`,
          boxSizing: 'border-box',
          backgroundColor: withBackground ? palette.background.default : 'white',
          position: 'sticky',
          zIndex: 10,
          left: 0,
          width: scrollable ? undefined : '100%',
          minWidth: scrollable ? '100%' : undefined,
          alignSelf: scrollable ? 'flex-start' : undefined,
          top,
          boxShadow: isSticky && withShadow ? shadows[1] : undefined,
          borderBottom: isSticky && withShadow ? `1px solid ${color.grey[10]}` : undefined,
        }}
        ref={(node) => {
          if (node) {
            (newRef as MutableRefObject<HTMLDivElement>).current = node;
          }
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
      >
        {children}
      </StickyContainer>
    );
  }
);

const StickyContainer = styled.div`
  &:last-child {
    flex: 1;
  }
`;

export default PageSection;
