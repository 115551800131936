import Api, { Accommodation, AccommodationSpecificity, AccommodationStatus, Cloud } from '@ambuliz/sabri-core';
import { startOfMinute } from 'date-fns';
import { useState } from 'react';

export type AccommodationToUpsert = {
  accommodationId?: string;
  bedId?: string;
  unitId?: string;
  startAt: Date | null;
  endAt?: Date | null;
  visitId?: string;
  isEndEstimated: boolean;
  specificities?: AccommodationSpecificity[];
  comment?: string | null;
  reason?: string | null;
  thesaurusItemId?: string | null;
};

const useAccommodation = (id?: string) => {
  const [loading, setLoading] = useState(false);

  const create = async (accommodation: AccommodationToUpsert) => {
    setLoading(true);
    try {
      await Cloud.upsertAccommodation({
        ...accommodation,
        startAt: startOfMinute(accommodation.startAt as Date),
        endAt: accommodation.endAt ? startOfMinute(accommodation.endAt) : undefined,
      });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const update = async (
    parameters: {
      specificities?: AccommodationSpecificity[];
      startAt?: Date | null;
      endAt?: Date | null;
      isEndEstimated?: boolean;
      bedId?: string | null;
      unitId?: string | null;
      status?: AccommodationStatus;
      comment?: string | null;
    },
    accommodationId?: string
  ) => {
    const idToUse = accommodationId ? accommodationId : id;
    if (idToUse) {
      try {
        setLoading(true);
        const accommodation = await new Api.Query(Accommodation)
          .select('bed.id', 'startAt', 'endAt', 'isEstimatedEnd', 'visit.id', 'specificities', 'unit.id')
          .get(idToUse);

        await Cloud.upsertAccommodation({
          accommodationId: idToUse,
          status: parameters.status || accommodation.status,
          bedId: parameters.bedId === undefined ? accommodation.bed?.id : parameters.bedId,
          unitId: parameters.unitId || accommodation.unit?.id,
          visitId: accommodation.visit?.id,
          startAt:
            parameters.startAt === undefined || parameters.startAt === null
              ? (accommodation.startAt as Date)
              : startOfMinute(parameters.startAt),
          endAt:
            parameters.endAt === undefined
              ? accommodation.endAt
              : parameters.endAt
              ? startOfMinute(parameters.endAt)
              : undefined,
          specificities:
            parameters.specificities === undefined ? accommodation.specificities : parameters.specificities,
          isEndEstimated:
            parameters.isEndEstimated === undefined ? accommodation.isEstimatedEnd : parameters.isEndEstimated,
          comment: parameters.comment === undefined ? accommodation.comment : parameters.comment,
        });
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  const cancel = async () => {
    setLoading(true);
    if (id) {
      try {
        await Cloud.cancelAccommodation({ accommodationId: id });
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  const closeBeds = async ({ bedIds, startAt, endAt, comment, specificities }: CloseBedsParams) => {
    setLoading(true);
    try {
      await Cloud.closeMultipleBeds({ areaIds: bedIds, startAt, endAt, comment, specificities });
    } catch (err) {
      throw err;
    }
    setLoading(false);
  };

  return { create, update, cancel, closeBeds, loading };
};

type CloseBedsParams = {
  bedIds: string[];
  startAt: Date;
  endAt?: Date;
  specificities?: AccommodationSpecificity[];
  comment?: string;
};

export default useAccommodation;
