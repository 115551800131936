import Api, { Area, HealthCenter } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useAuthentication } from 'core/authentication';
import { useMemo } from 'react';

type UseHospitalAreasParams = {
  enabled: boolean;
};

export const useHospitalAreas = (params?: UseHospitalAreasParams) => {
  const { healthCenter } = useAuthentication();

  const { results, isLoading } = useParseQuery(
    new Api.Query(Area)
      .equalTo('externalTypeCode', 'hospital')
      .equalTo('healthCenter', HealthCenter.createWithoutData(healthCenter.id)),
    {
      enabled: params?.enabled,
    }
  );

  const sortedResults = useMemo(() => results.sort((a, b) => a.name.localeCompare(b.name)), [results]);

  return {
    hospitals: sortedResults,
    isLoading,
  };
};
