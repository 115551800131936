import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import AccommodatedIcon from 'common/components/Icons/Accommodated';
import CovidIcon from 'common/components/Icons/Covid';
import HourglassIcon from 'common/components/Icons/Hourglass';
import { IsolatedIcon } from 'common/components/Icons/Isolated';
import { IsolatedAirIcon } from 'common/components/Icons/IsolatedAir';
import { IsolatedContactIcon } from 'common/components/Icons/IsolatedContact';
import { IsolatedDropletIcon } from 'common/components/Icons/IsolatedDroplet';
import { IsolatedProtectorIcon } from 'common/components/Icons/IsolatedProtector';
import PrivateRoomIcon from 'common/components/Icons/PrivateRoom';
import { ReactElement } from 'react';

export const specificityColor: Record<AccommodationSpecificity, 'warning' | 'error' | 'primary' | 'info' | 'danger'> = {
  COVID: 'error',
  ACCOMMODATED: 'warning',
  ISOLATED: 'danger',
  ISOLATED_AIR: 'danger',
  ISOLATED_DROPLET: 'danger',
  ISOLATED_CONTACT: 'danger',
  ISOLATED_PROTECTOR: 'danger',
  PRIVATE_ROOM: 'info',
  WAITING_FOR_DOWNSTREAM_BED: 'primary',
};

export const specificityIcon: Record<AccommodationSpecificity, ReactElement> = {
  COVID: <CovidIcon />,
  ACCOMMODATED: <AccommodatedIcon />,
  ISOLATED: <IsolatedIcon />,
  ISOLATED_AIR: <IsolatedAirIcon />,
  ISOLATED_DROPLET: <IsolatedDropletIcon />,
  ISOLATED_CONTACT: <IsolatedContactIcon />,
  ISOLATED_PROTECTOR: <IsolatedProtectorIcon />,
  PRIVATE_ROOM: <PrivateRoomIcon />,
  WAITING_FOR_DOWNSTREAM_BED: <HourglassIcon />,
};
