import Api, { Porter, User } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { liveQueryClient } from 'core/live-query-client';
import { useCallback, useEffect, useState } from 'react';

const usePorters = (enabled?: boolean) => {
  const { results: users, isLoading: userQueryLoading } = useParseQuery(userQuery, { enabled });

  const [porters, setPorters] = useState<Porter[]>([]);
  const [isLoading, setLoading] = useState(true);

  const handleUpdatePorter = useCallback((porter: Porter) => {
    setPorters((prevPorters) => prevPorters.map((prevPorter) => (prevPorter.id === porter.id ? porter : prevPorter)));
  }, []);

  const handleDeletePorter = useCallback((porter: Porter) => {
    setPorters((prevPorters) => prevPorters.filter((prevPorter) => prevPorter.id !== porter.id));
  }, []);

  useEffect(() => {
    setPorters(users.map((user) => user.porter!));

    if (users.length === 0 && !userQueryLoading) {
      setLoading(false);
      return;
    }

    const porterIds = users.map((user) => user.porter!.id);

    const subscription = liveQueryClient.subscribe(porterQuery(porterIds), Api.User.current()?.getSessionToken());
    subscription.on('open', () => setLoading(false));
    subscription.on('update', (porter) => handleUpdatePorter(porter as Porter));
    subscription.on('delete', (porter) => handleDeletePorter(porter as Porter));

    return () => liveQueryClient.unsubscribe(subscription);
  }, [users, handleDeletePorter, handleUpdatePorter, userQueryLoading]);

  return { porters, isLoading };
};

export default usePorters;

const userQuery = new Api.Query(User)
  .equalTo('role', 'PORTER')
  .exists('porter')
  .equalTo('disabled', false)
  .include('porter');
const porterQuery = (ids: string[]) => new Api.Query(Porter).containedIn('objectId', ids);
