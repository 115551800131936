const convertFileToBase64 = (file: File): Promise<string> => {
  const fileReader = new FileReader();
  if (file) {
    fileReader.readAsDataURL(file);
  }
  return new Promise((resolve) => {
    fileReader.onload = function (event) {
      resolve(event?.target?.result as string);
    };
  });
};

export default convertFileToBase64;
