import { LoadingButton } from '@mui/lab';
import { ChangeEvent, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { toast } from 'common/toast';
import { Cloud } from '@ambuliz/sabri-core';
import { useMyHealthCenter } from 'core/healthcenter';
import { convertFileToBase64 } from 'common/utils';

const UploadCSVDialog = ({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const { myHealthCenter } = useMyHealthCenter();

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile: File = (event.target.files as FileList)[0];

    if (uploadedFile && uploadedFile.type !== 'text/csv') {
      toast.error('Veuillez fournir un fichier .csv');

      return;
    }
    setFile(uploadedFile);
  };

  const onConfirm = async () => {
    setLoading(true);
    if (file && myHealthCenter) {
      const base64File = await convertFileToBase64(file);
      try {
        await Cloud.importThesaurus({ file: base64File, healthCenterId: myHealthCenter.id });
        toast.success('Le thésaurus a été importé avec succès');
      } catch {
        toast.error("Une erreur s'est produite lors de la lecture du fichier");
      }
    }
    onSuccess();
    handleClose();
  };

  const handleClose = () => {
    setFile(undefined);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Importer le Thésaurus (.csv)</DialogTitle>
      <DialogContent>
        <TextField type="file" onChange={onFileChange}></TextField>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="text">
          Annuler
        </Button>
        <LoadingButton onClick={onConfirm} loading={loading} disabled={!file}>
          Confirmer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadCSVDialog;
