import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, Paper, Stack, Typography } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { toast } from 'common/toast';
import { FormEvent, useState } from 'react';
import { NormalizedUser, UserUpdateFormState } from '../context';

type ReEnableUserDialogProps = {
  open: boolean;
  userId: string;
  healthCenterId: string;
  disabledReason?: string;
  onClose: () => void;
  onSubmit: (user: UserUpdateFormState) => Promise<void | NormalizedUser>;
};

const ReEnableUserDialog = ({
  userId,
  healthCenterId,
  disabledReason,
  open,
  onClose,
  onSubmit,
}: ReEnableUserDialogProps) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      await onSubmit({ objectId: userId, disabled: false, healthCenterId });
      toast.success(i18n.accountHasBeenReEnabled);
      onClose();
    } catch (err) {
      toast.error(err?.message || i18n.errorReEnablingAccount);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogHeader title="Réactiver le compte" onClose={onClose} />
        <DialogContent>
          <Stack spacing={4}>
            <Typography variant="h4">{i18n.reEnableAccountQuestion}</Typography>
            <Paper sx={{ padding: 4, bgcolor: palette.primary.light }} elevation={0}>
              <Typography color="secondary" fontWeight={600}>
                {i18n.accountDisabledReason + ' : '}
                <Typography component="span" color="inherit" fontWeight="normal">
                  {disabledReason ? `"${disabledReason}"` : i18n.notProvided}
                </Typography>
              </Typography>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={onClose} disabled={loading}>
              {i18n.cancel}
            </Button>
            <LoadingButton type="submit" loading={loading}>
              {i18n.reEnable}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReEnableUserDialog;
