import Api, { Cloud, HealthCenter } from '@ambuliz/sabri-core';
import { Alert, Stack } from '@mui/material';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { PageHeader, PageLoader } from 'core/layout';
import { useEffect, useState } from 'react';
import { HealthCenterConfigForm } from 'superadmin/components/HealthCenterConfig';
import ExportStructureButton from './ExportStructureButton';
import { useAuthentication } from 'core/authentication';

const getHealthCenter = async (id: string) => await new Api.Query(HealthCenter).get(id);
const getHealthCenterGroups = async () => (await Cloud.getHealthCenterGroups()).map((group) => group.name);

const HealthCenterManagement = () => {
  const { healthCenter } = useAuthentication();

  if (!healthCenter?.id) {
    return null;
  }

  return <HealthCenterManagementPage healthCenterId={healthCenter.id} />;
};

const HealthCenterManagementPage = ({ healthCenterId }: { healthCenterId: string }) => {
  const [healthCenter, setHealthCenter] = useState<HealthCenter>();
  const [healthCenterGroups, setHealthCenterGroups] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const refetch = () => {
    fetchHealthCenter(healthCenterId);
  };

  const fetchHealthCenter = async (id: string) => {
    setError(false);
    try {
      setHealthCenterGroups(await getHealthCenterGroups());
      setHealthCenter(await getHealthCenter(id));
    } catch (error) {
      toast.error(i18n.globalError);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHealthCenter(healthCenterId);
  }, [healthCenterId]);

  if (loading || !healthCenter) {
    return <PageLoader />;
  }

  if (error) {
    return <Alert severity="error">{i18n.globalError}</Alert>;
  }

  return (
    <Stack paddingX={50}>
      <PageHeader title={`Établissement - ${healthCenter.name}`}>
        <ExportStructureButton username={healthCenter.username} />
      </PageHeader>
      <HealthCenterConfigForm healthCenter={healthCenter} healthCenterGroups={healthCenterGroups} onSuccess={refetch} />
    </Stack>
  );
};

export default HealthCenterManagement;
