import { MenuItem, Select } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { useAuthentication } from 'core/authentication';

export const AppBarHealthCenterSelect = () => {
  const { healthCenters, selectHealthCenter, healthCenter } = useAuthentication();
  const sortedHealthCenters = healthCenters.sort((a, b) => a.name.localeCompare(b.name));

  if (healthCenter && healthCenters.length < 2) {
    return (
      <EllipsisTypography color="secondary" textAlign="center" maxLines={2} tooltipProps={{ placement: 'left' }}>
        {healthCenter.name}
      </EllipsisTypography>
    );
  }

  return (
    <Select onChange={(event) => selectHealthCenter(event?.target?.value)} value={healthCenter?.id}>
      {sortedHealthCenters.map((hc) => (
        <MenuItem key={hc.id} value={hc.id}>
          {hc.name}
        </MenuItem>
      ))}
    </Select>
  );
};
