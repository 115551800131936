import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, Stack, TextField, Typography } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { ChangeEvent, FormEvent, useState } from 'react';
import { NormalizedUser, UserUpdateFormState } from '../context';

type DisableUserDialogProps = {
  open: boolean;
  userId: string;
  healthCenterId: string;
  onClose: () => void;
  onSubmit: (user: UserUpdateFormState) => Promise<void | NormalizedUser>;
};

const DisableUserDialog = ({ userId, healthCenterId, open, onClose, onSubmit }: DisableUserDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [disabledReason, setDisabledReason] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDisabledReason(event.target.value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      await onSubmit({ objectId: userId, disabled: true, healthCenterId, disabledReason });
      toast.success(i18n.accountHasBeenDisabled);
      onClose();
    } catch (err) {
      toast.error(err?.message || i18n.errorDisablingAccount);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited: () => setDisabledReason('') }}>
      <form onSubmit={handleSubmit}>
        <DialogHeader title={i18n.disableAccount} onClose={onClose} />
        <DialogContent>
          <Stack spacing={4}>
            <Typography variant="h4">{i18n.accountDisabledReasonQuestion}</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder={i18n.accountDisabledReason}
              onChange={handleChange}
              value={disabledReason}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={onClose} disabled={loading}>
              {i18n.cancel}
            </Button>
            <LoadingButton type="submit" loading={loading}>
              {i18n.confirm}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DisableUserDialog;
