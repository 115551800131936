import { User, UserRole } from '@ambuliz/sabri-core';
import { useDebounce } from 'common/hooks';
import useParseQuery from 'common/hooks/useParseQuery';
import { useAuthentication } from 'core/authentication';
import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import { usePagination } from '../hooks/usePagination';
import { NormalizedUser, UserCreateForm, UserManagementContextValue, UserUpdateForm } from './UserManagement.model';
import { createUser, updateUser, useBuildUserQuery } from './UserManagement.queries';

export const UserManagementContext = createContext({} as UserManagementContextValue);

export const UserManagementContextProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { user, healthCenter } = useAuthentication();
  const pagination = usePagination();
  const [name, setName] = useState('');
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [disabled, setDisabled] = useState(false);
  const debouncedName = useDebounce(name, 300);
  const [users, setUsers] = useState<NormalizedUser[]>([]);
  const [isLoading, setLoading] = useState(true);

  const findUsersQuery = useBuildUserQuery(pagination, user, debouncedName, roles, healthCenter.id, disabled);
  const { results: userQueryResults, isLoading: userQueryLoading, refetch } = useParseQuery(findUsersQuery);

  useEffect(() => {
    findUsersQuery.count().then(pagination.setCount);
  }, [pagination.setCount, findUsersQuery]);

  const mapUsers = useCallback(async (users: User[]) => {
    const mappedUsers: NormalizedUser[] = [];

    for (const user of users) {
      mappedUsers.push({
        objectId: user.id,
        role: user.role,
        username: user.username,
        unit: user.unit?.id,
        unitName: user.unit?.name,
        firstName: user.porter?.firstName,
        lastName: user.porter?.lastName,
        password: user.password,
        units: user.units?.map((unit) => unit.id) || [],
        notificationConfigurations: user.notificationConfigurations,
        navigationACL: user.navigationACL,
        isReadOnly: user.isReadOnly,
        whitelistedIPs: user.whitelistedIPs,
        lastConnectionAt: user.lastConnectionAt,
        disabled: user.disabled,
        disabledReason: user.disabledReason,
      });
    }

    setUsers(mappedUsers);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userQueryResults.length > 0 && !userQueryLoading) {
      mapUsers(userQueryResults);
    }
  }, [userQueryResults, userQueryLoading, mapUsers]);

  const handleUpdateUser = async (user: UserUpdateForm) => {
    await updateUser(user);
    await refetch();
    await findUsersQuery.count().then(pagination.setCount);
  };

  const handleCreateUser = async (user: UserCreateForm) => {
    await createUser(user);
    await refetch();
    await findUsersQuery.count().then(pagination.setCount);
  };

  return (
    <UserManagementContext.Provider
      value={{
        pagination,
        filters: {
          roles,
          setRoles,
          name,
          setName,
          disabled,
          setDisabled,
        },
        users,
        loading: isLoading,
        createUser: handleCreateUser,
        updateUser: handleUpdateUser,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};
