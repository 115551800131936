import Api, { HealthCenter, Unit, UnitSpecialty, UnitType } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';
import { useAuthentication } from 'core/authentication';

type UnitSearchParams = {
  name?: string;
  types?: UnitType[];
  specialties?: UnitSpecialty[];
  departments: string[];
  page?: number;
  pageSize?: number;
};

export const useUnitSearch = (params: UnitSearchParams) => {
  const { healthCenter } = useAuthentication();
  const query = new Api.Query(Unit).include('areas.name');

  query.ascending('name').equalTo('healthCenter', HealthCenter.createWithoutData(healthCenter.id));

  const pageSize = params.pageSize || 10;

  if (params.name) {
    query.matches('name', new RegExp(`${params.name}`, 'i'));
  }

  if (params.types && params.types.length > 0) {
    query.containedIn('type', params.types);
  }

  if (params.specialties && params.specialties.length > 0) {
    query.containedIn('specialties', params.specialties);
  }

  if (params.page) {
    query.skip(params.page * pageSize);
  }

  if (params.departments && params.departments.length > 0) {
    query.containedIn('department', params.departments);
  }

  query.limit(pageSize);

  const { results, isLoading: isUnitsQueryLoading, refetch } = useParseQuery(query, { enableLiveQuery: false });
  const { count, isLoading: isCountQueryLoading } = useParseQueryCount(query);

  return {
    units: results,
    unitsCount: count,
    isLoading: isUnitsQueryLoading || isCountQueryLoading,
    refetch,
  };
};
